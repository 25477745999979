var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ion-header',[_c('ion-toolbar',[_c('ion-buttons',{attrs:{"slot":"start"},slot:"start"},[_c('ion-button',{staticClass:"w-8 h-8",on:{"click":function($event){return _vm.$ionic.modalController.dismiss()}}},[_c('svg',{attrs:{"width":"8","height":"14","viewBox":"0 0 8 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2.82808 6.99999L7.77808 11.95L6.36408 13.364L7.60158e-05 6.99999L6.36408 0.635986L7.77808 2.04999L2.82808 6.99999Z","fill":"black"}})])])],1),_c('ion-title',[_vm._v(_vm._s(_vm.title))]),(Object.keys(_vm.cart).length)?_c('ion-buttons',{attrs:{"slot":"end"},slot:"end"},[_c('ion-button',{on:{"click":function($event){_vm.isManage = !_vm.isManage}}},[_vm._v(_vm._s(_vm.isManage ? "Done" : "Manage"))])],1):_vm._e()],1)],1),_c('ion-content',{staticClass:"ion-padding"},[_c('main',{staticClass:"px-4 py-4"},[(Object.keys(_vm.cart).length)?_c('div',_vm._l((Object.values(_vm.cart)),function(each,i){return _c('div',{key:i,staticClass:"flex items-center justify-between space-x-3 hover:bg-gray-100 p-2 rounded-md transition cursor-pointer",on:{"click":() => {
              _vm.$ionic.modalController.dismiss();
              _vm.$router.push({ name: 'Checkout', params: { id: each.id } });
            }}},[_c('div',{staticClass:"flex-none w-12 h-12 bg-gray-100 rounded"},[_c('img',{staticClass:"w-full h-full object-contain",attrs:{"src":`${each.bannerUrl}?x-oss-process=image/resize,w_480,limit_1/format,webp`},on:{"error":(e) =>
                  (e.target.src = `${_vm.BASE_URL}img/${_vm.platform}/empty_food.png`)}})]),_c('div',{staticClass:"text-gray-900 flex-1 w-full self-start"},[_c('h1',[_vm._v(_vm._s(each.name))]),_c('p',{staticClass:"text-gray-500 truncate text-sm"},[_vm._v(" "+_vm._s(each.cart.length)+" items "),(each.distance > -1)?_c('span',[_vm._v("∙ "+_vm._s(_vm.formatDistance(each.distance)))]):_vm._e()])]),(_vm.isManage)?_c('button',{staticClass:"flex-none hover:text-red-500",on:{"click":function($event){$event.preventDefault();return _vm.onRemoveCart($event, each)}}},[_c('svg',{staticClass:"w-5 h-5 fill-current",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}}),_c('path',{attrs:{"d":"M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"}})])]):_vm._e()])}),0):_c('div',{staticClass:"h-full w-full flex flex-col items-center text-sm p-2 pt-28 bg-white rounded-md"},[_c('SvgIcon',{staticClass:"mx-auto",attrs:{"name":"empty_cart"}}),_c('div',{staticClass:"my-6 text-center text-gray-800"},[_c('p',{staticClass:"text-gray-700 text-base font-bold"},[_vm._v("Cart is empty")]),_c('p',{staticClass:"text-gray-500"},[_vm._v(" Add items to your cart and place order here. ")])]),_c('button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"btn primary px-4 py-2 rounded-md mx-auto block font-bold text-center w-48 theme",on:{"click":() => {
              _vm.$ionic.modalController.dismiss();
            }}},[_vm._v(" Continue Browsing ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }