
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Dropdown extends Vue {
  @Prop({
    required: true
  })
  isDropdownOpen?: boolean;

  get _isDropdownOpen() {
    return this.isDropdownOpen;
  }
  set _isDropdownOpen(open) {
    this.$emit("update:isDropdownOpen", open);
  }
}
