
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Button extends Vue {
  @Prop({
    required: false
  })
  readonly icon?: string;
}
