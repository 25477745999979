import axios, { AxiosInstance, AxiosResponse } from "axios";
import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { cartStore } from "@/store/modules/cart";
import empty from "is-empty";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 35000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  withCredentials: true
});

// Request interceptors
instance.interceptors.request.use(
  (config) => {
    config.headers["App-Version"] = process.env.VUE_APP_VERSION;
    const cachedToken = userStore.user.accessToken;
    if (!empty(cachedToken)) {
      config.headers["Authorization"] = `Bearer ${cachedToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  async (error) => {
    switch (error.response.status) {
      case 401:
        var Vue = document.querySelector("#app").__vue__;
        Vue.$ionic.toastController
          .create({
            header: "Oops, please refresh again to continue.",
            position: "top",
            color: "primary",
            duration: 5000,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());

        break;
      default:
        var Vue = document.querySelector("#app").__vue__;
        switch (error?.response?.data?.error?.code) {
          case "DELIVERY_NOT_AVAILABLE":
            Vue.$ionic.toastController
              .create({
                header: `${error.response.data?.error?.message}, please change address or try pickup 😊`,
                position: "top",
                color: "primary",
                duration: 5000,
                buttons: [
                  {
                    icon: "close",
                    role: "cancel"
                  }
                ]
              })
              .then((x) => x.present());
            break;
          case "LOGIN_REQUIRED":
            Vue.$ionic.toastController
              .create({
                header: `${error.response.data?.error?.message}`,
                position: "top",
                color: "primary",
                duration: 5000,
                buttons: [
                  {
                    icon: "close",
                    role: "cancel"
                  }
                ]
              })
              .then((x) => {
                x.present();
                Vue.$router.push({
                  name: "Login",
                  query: { redirect: "GO_BACK" }
                });
              });
            break;

          default:
            error.response.data?.error?.message &&
              Vue.$ionic.toastController
                .create({
                  header: `${error.response.data?.error?.message}`,
                  position: "top",
                  color: "danger",
                  duration: 5000,
                  buttons: [
                    {
                      icon: "close",
                      role: "cancel"
                    }
                  ]
                })
                .then((x) => x.present());
        }
    }
    return Promise.reject(error.response);
  }
);

export { AxiosInstance, AxiosResponse };
export default instance;
