
import { Component, Vue, Watch } from "vue-property-decorator";
import { Loading, SideMenu, Drawer } from "@/components";
import { Header } from "@/components/Navigation";

import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { cartStore } from "@/store/modules/cart";
import { tableReservationStore } from "@/store/modules/tableReservation";
import { uiStore } from "@/store/modules/ui";
import { isAlaCartePage, isSandbox } from "@/utils/page";
import request from "@/utils/request";

import detectMobile from "@/utils/user-agent";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import cookies from "@/utils/cookies";
import { locales } from "@/i18n/locale";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import empty from "is-empty";

import BottomNav from "@/components/Navigation/BottomNav.vue";
import { menuStore } from "./store/modules/menu";

import { FooterLogo } from "@/components/General/DynamicImages.vue";
import Modal from "@/components/Feedback/Modal.vue";
import Review from "@/components/Custom/Review.vue";
import { trxStore } from "@/store/modules/transaction";
import rmPlugin from "@/utils/plugin";

import { isBoost, isNestle, isInIframe } from "@/constant/platform";

@Component({
  name: "App",
  components: {
    BottomNav,
    Header,
    Loading,
    SideMenu,
    Drawer,
    FooterLogo,
    Modal,
    Review
  },
  metaInfo() {
    let facebook_domain_verification = [] as any;
    const subdomain = window.location.host.split(".")[0];
    if (
      subdomain.indexOf("localhost") > -1 ||
      subdomain.indexOf("test-demo") > -1
    ) {
      facebook_domain_verification = [
        {
          vmid: "facebook-domain-verification",
          name: "facebook-domain-verification",
          content: "testing"
        }
      ];
    } else if (subdomain.indexOf("selections") > -1) {
      facebook_domain_verification = [
        {
          vmid: "facebook-domain-verification",
          name: "facebook-domain-verification",
          content: "o8olf6zcc5pwj2ey3qb87lgf4lepkh"
        }
      ];
    }
    return {
      title: "à la carte",
      meta: [...facebook_domain_verification]
    };
  }
})
export default class App extends Vue {
  isDrawerOpen = false;
  transitionName = "fade";
  isLoaded = false;
  prompt = false;
  isAlipay = false;
  isSenHeng = false;
  isBoost = false;
  isTng = false;
  bannerVisible = false;
  affiliateCode: string | null = null;

  get isAuthenticated() {
    return !empty(this.userStore.user.accessToken);
  }
  get isNestleDev() {
    return isNestle() && window.location.host.indexOf("localhost") > -1;
  }

  get isReviewOrder() {
    return trxStore.isReviewOrder;
  }

  get isOngoingBoostPayment() {
    return trxStore.isOngoingBoostPayment;
  }
  get isLoading() {
    return uiStore.isLoading;
  }
  get isFoodRedirect() {
    return this.$route.name == "FoodView";
  }
  get isStoreRedirect() {
    return this.$route.name == "StoreView";
  }
  get isTransactionRedirect() {
    return this.$route.name == "OrderSummary";
  }

  get isAddressRedirect() {
    return this.$route.name == "AddAddress";
  }

  get isVehicleRedirect() {
    return this.$route.name == "VehicleInfo";
  }
  get isWeb() {
    return merchantStore.isWeb;
  }

  get isMobile() {
    return detectMobile();
  }
  get subtotal() {
    return cartStore.subtotalAmount;
  }
  get totalItems() {
    return cartStore.totalItems;
  }
  get currentStore() {
    return merchantStore.currentStore;
  }
  get isStoreView() {
    const { id = "" } = this.$route.params;
    return id !== "";
  }
  get userStore() {
    return userStore;
  }
  get merchantSubdomain() {
    const subdomain = window.location.host.split(".")[0];
    if (subdomain.indexOf("192") > -1) {
      return "test-demo";
    } else if (
      subdomain.indexOf("localhost") > -1 ||
      subdomain === "sb" ||
      window.location.host.indexOf("ap.ngrok.io") > -1
    ) {
      return "test-web";
    } else if (
      window.location.host.startsWith("alacarte.my") ||
      window.location.host.startsWith("web.alacarte.my")
    ) {
      return "web";
    }

    return subdomain;
  }

  get isAlaCarteRootPage() {
    const isRootPath = window.location.pathname === "/";
    return isAlaCartePage() && isRootPath;
  }

  get shareableLink() {
    return `${window.location.protocol}//${window.location.host}/${this.currentStore?.id}`;
  }

  get platform() {
    return merchantStore.platform;
  }

  get env() {
    return process.env;
  }

  get isNestle() {
    return isNestle();
  }

  get isUserBinded() {
    return userStore.user.isBinded;
  }

  @Watch("isUserBinded")
  async onUserChanged(userBinded: boolean, _) {
    try {
      // bind referred voucher to user
      const externalUserId = await cookies.getSessionStorage("externalUserId");
      const code = await cookies.getSessionStorage("refrred-promo-code");

      if (userBinded && code && externalUserId) {
        await request.post(`v3/web/voucher/bind`, { code, externalUserId });

        // remove once successfully bound
        cookies.removeSessionStorage("refrred-promo-code");
        cookies.removeSessionStorage("externalUserId");

        this.$ionic.toastController
          .create({
            header:
              "Voucher issued successfully, please find it on the checkout page.",
            position: "top",
            color: "success",
            duration: 1500,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());
      }
    } catch (e) {}
  }

  reload() {
    window.location.reload();
  }

  onFooterIconClicked() {
    window.location.href = "https://alacarte.my";
  }
  onDismissPrivacyBanner() {
    cookies.set("hidePrivacyUpdate", true);
    this.bannerVisible = false;
  }

  formatNumber(number: string): string {
    return parsePhoneNumberFromString(number, "MY")?.number.toString() || "";
  }

  private async setPlatform() {
    // Set the language
    if (merchantStore.platform == "") {
      if (
        this.$route.query?.clientId &&
        this.$route.query?.clientId.includes("boost") &&
        this.$route.query?.customerId
      ) {
        const boostCustomerId =
          typeof this.$route.query.customerId === "string"
            ? this.$route.query.customerId
            : this.$route.query.customerId.join("");
        await userStore.setBoostCustomerId(boostCustomerId);
      }

      if (this.isAlipay) {
        this.$i18n.locale = locales.CN;
        merchantStore.updatePlatform({ platform: "ALIPAY", isWeb: false });
        merchantStore.updateLocale(locales.CN);
        uiStore.setLoader(false);
        dayjs.locale("zh-cn");
      } else if (this.isTng) {
        this.$i18n.locale = locales.EN;
        merchantStore.updatePlatform({ platform: "TNG", isWeb: false });
        uiStore.setLoader(false);
        merchantStore.updateLocale(locales.EN);
      } else if (this.isBoost) {
        merchantStore.updatePlatform({ platform: "BOOST", isWeb: true });
        merchantStore.updateLocale(locales.EN);
      } else if (this.isSenHeng) {
        merchantStore.updatePlatform({ platform: "SENHENG", isWeb: true });
        merchantStore.updateLocale(locales.EN);
      } else {
        merchantStore.updatePlatform({ platform: "WEB", isWeb: true });
        merchantStore.updateLocale(locales.EN);
      }
      // IMPORTANT: need to add the platform classname in the list below so that during compile and build, it will detect it and compile to css
      // boost, senheng, fa-icon

      if (this.isAlipay || this.isTng) {
        if (this.$route.query?.customerID) {
          const customerID = String(this.$route.query.customerID);
          await userStore.setCustomerID(customerID);
        }
      }

      // TODO: For TNGD set the platform accordingly
      merchantStore.updateIsShowApprovedOnly(
        isAlaCartePage() ||
          this.isAlipay ||
          this.isTng ||
          this.isBoost ||
          this.isSenHeng
      );
      if (this.isWeb) {
        document.title = "à la carte";
      }
    }
  }

  private processSignedRequest(signedRequest) {
    if (signedRequest) {
      let decodedSR = JSON.parse(atob(signedRequest.split(".")[1]));
      userStore.setPluginUser({
        merchantID: decodedSR["merchantId"],
        countryCode: decodedSR["userCountryCode"],
        phoneNumber: decodedSR["userPhone"]
      });
    }
  }

  beforeMount() {
    merchantStore.UPDATE_HISTORY_LEN(
      JSON.parse(JSON.stringify(window.history.length))
    );
    const urlParams = new URLSearchParams(window.location.search);
    const boostClientId = urlParams.get("clientId");
    const ac = urlParams.get("ac");

    if (ac?.match(/^[0-9a-zA-Z]+$/)) {
      this.affiliateCode = ac;
    } else if (ac) {
      setTimeout(() => {
        this.$ionic.toastController
          .create({
            header: "invalid agent code",
            position: "top",
            color: "primary",
            duration: 2000,
            buttons: [
              {
                icon: "close",
                role: "cancel"
              }
            ]
          })
          .then((x) => x.present());
      }, 1000);
    }

    // TODO: Uncomment with alacarte 2.0
    // const campaignNo = urlParams.get("campaignNo");
    // if (!empty(campaignNo)) {
    //   // for RM5 OFF first 3 orders min spend RM30 campaign
    //   if (campaignNo == "4381988553") {
    //     userStore.setNewUserCampaign(true);
    //   }
    // }
    // const tableId = urlParams.get("tableId");
    // if (!empty(tableId)) {
    //   userStore.setNewUserCampaign(true);
    // }

    if (window.location.href.indexOf("/search?id=") > -1) {
      cookies.setSessionStorage("isSearchBarReset", "YES");
    }

    this.isBoost = (boostClientId || "").includes("boost") || isBoost();
    const platform = urlParams.get("platform");
    this.isAlipay = platform == "ALIPAY";
    this.isTng = platform == "TNG";
    // this.isSenHeng = platform == "senheng" || (cookies.get("isSenHeng") || "") == "YES";
    // if (this.isSenHeng) {
    //   cookies.set("isSenHeng", "YES");
    // }

    // store referred voucher code in local storage
    const externalUserId = urlParams.get("externalUserId");
    const code = urlParams.get("code");

    if (code && externalUserId) {
      cookies.setSessionStorage("refrred-promo-code", code);
      cookies.setSessionStorage("externalUserId", externalUserId);
      const { id } = this.$route.params;
      if (id) {
        cookies.setSessionStorage(`promo-${id}`, externalUserId);
      }
    }
  }

  protected async created() {
    // Block nestle website, only allow in iframe
    if (isNestle() && !isInIframe()) {
      window.location.href = isSandbox()
        ? "https://sb.alacarte.my"
        : "https://alacarte.my";
      return;
    }

    const beforeEach = async (to, from, next) => {
      const id = await cookies.get("currentStore");
      if (
        !userStore.isLoggedIn &&
        to.matched.some((record) => record.meta.requiresAuth) &&
        empty(to.query)
      ) {
        next({ name: "Login" });
      } else if (
        id &&
        to.name &&
        !to.params.id &&
        to.matched.some((record) => !record.meta.ignoreStoreId)
      ) {
        next({ name: to.name, params: { id: id } });
      }

      // Auth user
      let transitionName = to.meta?.transitionName || from.meta?.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.meta?.depth || 0;
        const fromDepth = from.meta?.depth || 0;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || "fade";

      // if (!uiStore.isLoading) {
      //   switch (window.location.pathname) {
      //     case "/search":
      //       break;
      //     case "/":
      //       if (merchantStore.allStores.length > 0) {
      //         break;
      //       }
      //     default:
      //     // uiStore.setLoader(true);
      //   }
      // }

      next();
    };

    this.$router.beforeEach((to, from, next) => {
      if (isNestle()) {
        rmPlugin.init("merchant.", (signedRequest) => {
          this.processSignedRequest(signedRequest);
          beforeEach(to, from, next);
        });
      } else {
        beforeEach(to, from, next);
      }
    });

    const afterEach = async (to, from) => {
      // if (this.isAlaCarteRootPage && window.outerWidth < 1024 && window.location.pathname === "/" && this.$refs.app) {
      //   (this.$refs.app as HTMLElement).style.paddingBottom = "0";
      // } else if (this.$refs.app) {
      //   (this.$refs.app as HTMLElement).style.paddingBottom = "8rem";
      // }
      // Auth user
      if (!this.isAuthenticated || userStore.isPendingLogin) {
        if (!this.isAuthenticated) {
          try {
            await this.setPlatform();
            if (empty(this.$route.query?.token)) {
              await userStore
                .authenticate(this.affiliateCode)
                .finally(async () => {
                  this.isLoaded = true;
                  uiStore.setLoader(false);
                  Promise.all([userStore.fetchProfile()]).finally(async () => {
                    switch (this.platform) {
                      case "SENHENG":
                        if (!userStore.user.senhengUserName) {
                          const urlParams = new URLSearchParams(
                            window.location.search
                          );
                          const accessToken = urlParams.get("access_token");
                          await userStore.oauth({
                            senhengAccessToken: accessToken
                          });
                        }
                        break;
                    }
                  });
                });
            } else {
              //coming from AliPay miniP
              const token = String(this.$route.query.token);

              await userStore.setAuth(token).finally(() => {
                this.isLoaded = true;
                uiStore.setLoader(false);
                Promise.all([userStore.fetchProfile()]).finally(() => {});
              });
            }
          } catch (e) {
            try {
              request.post(`v3/log/trace`, {
                data: {
                  line: "src/App.vue:827",
                  e,
                  statusCode: 463
                }
              });
            } catch (e) {
              console.error(e);
            }
            console.error(e);
          }
        }

        // Fetch all the banners
        // await bannerStore.fetchAllBanners();

        // deferred statements
        setTimeout(async () => {
          let order;
          const { reviewOrderID } = this.$route.query;

          if (reviewOrderID && !userStore.user?.isBinded) {
            userStore.setIsPendingLogin(true);
            this.$router.push({
              name: "Login",
              query: { redirect: "GO_BACK" }
            });
          } else if (userStore.user?.isBinded) {
            userStore.setIsPendingLogin(false);
          }

          order = await trxStore.getReviewOrder(reviewOrderID); //backend will cross check orderId with customerId

          if (order != null) {
            trxStore.setIsReviewOrder(true);
          }
        }, 1200); // add some delay to let the user see the food images before review modal
      }

      const { tableId } = this.$route.query;
      const { id } = this.$route.params;

      if (
        tableId &&
        !(await cookies.getSessionStorage("isGuidedToWalkInOnce"))
      ) {
        cookies.set("selectedDineType", "DINEIN");
        cookies.set("isCutleryRequired", false);
        cookies.setSessionStorage("isGuidedToWalkInOnce", "true");
      }

      if (id) {
        if (tableId) {
          cookies.setSessionStorage(`table-${id}`, String(tableId));
          userStore.setFromQR(true);
        }

        if (
          id &&
          !(merchantStore.currentStore && id === merchantStore.currentStore.id)
        ) {
          this.isLoaded = true;
          uiStore.setLoader(false);

          if (isAlaCartePage()) {
            merchantStore.clearMerchant();
          }
          merchantStore.clearStore();
          menuStore.clearMenu();
          cookies.set("currentStore", id);

          await merchantStore.fetchStoreSettings({ storeID: id });
          const cachedCart = JSON.parse(
            (await cookies.get(`cart-${id}`)) || "[]"
          );
          if (cachedCart) {
            cartStore.loadCart(cachedCart);
          }
          if (!menuStore.menus?.length) {
            await menuStore.fetchMenus(id);
          }
        } else {
          this.isLoaded = true;
          uiStore.setLoader(false);
        }
      }
    };

    this.$router.afterEach(async (to, from) => {
      if (isNestle()) {
        rmPlugin.init("merchant.", (signedRequest) => {
          this.processSignedRequest(signedRequest);
          afterEach(to, from);
        });
      } else {
        afterEach(to, from);
      }
    });

    const startApp = async () => {
      trxStore.SET_IS_MEAL_PLAN(
        JSON.parse((await cookies.get("isTransactionListMealPlan")) || "false")
      );
      userStore.SET_IS_BINDED(
        JSON.parse((await cookies.get("isBinded")) || "false")
      );
      userStore.SET_CUSTOMER_ID((await cookies.get("customerID")) || "");
      userStore.SET_BOOST_CUSTOMER_ID(
        (await cookies.get("boostCustomerId")) || ""
      );

      this.bannerVisible = (await cookies.get("hidePrivacyUpdate")) !== "true";

      const paths = window.location.pathname.split("/");
      const storeId = paths.find((each) => Number.isInteger(parseInt(each)));

      if (storeId) {
        const cachedCart = JSON.parse(
          (await cookies.get(`cart-${storeId}`)) || "null"
        );
        if (cachedCart) {
          cartStore.loadCart(cachedCart);
        }

        const cachedReservation = JSON.parse(
          (await cookies.get(`reservation-${storeId}`)) || "null"
        );
        if (cachedReservation) {
          tableReservationStore.setTableReservation(cachedReservation);
        }
      }
    };

    if (isNestle()) {
      rmPlugin.init("merchant.", (signedRequest) => {
        this.processSignedRequest(signedRequest);
        startApp();
      });
    } else {
      startApp();
    }
  }
}
