export default {
  isAlipayMP: () => {
    const result = new Promise(function(resolve, reject) {
      if (navigator.userAgent.indexOf("AliApp") > -1) {
        const isMiniProgram = new Promise(function(resolve, reject) {
          my.getEnv(function(res) {
            resolve(res.miniprogram);
          });
        });
        resolve(isMiniProgram);
      } else {
        resolve(false);
      }
    });

    return result;
  },
  switchTab: (url) => {
    my.switchTab({ url: url });
  },
  tradePay: (platform, data) => {
    return new Promise(function(resolve, reject) {
      if (platform === "TNG") {
        my.tradePay({
          paymentUrl: data, // TODO: testing
          success: (res) => {
            console.log("SUCCESS TRADE PAY", res);
            resolve({ success: true });
          },
          fail: (res) => {
            console.log("FAIL TRADE PAY", res);
            resolve({ success: false });
          }
        });
      } else {
        my.tradePay({
          orderStr: data,
          success: (res) => {
            console.log("SUCCESS TRADE PAY", res);
            resolve({ success: true });
          },
          fail: (res) => {
            console.log("FAIL TRADE PAY", res);
            resolve({ success: false });
          }
        });
      }
    });
  },
  postMessage: (data) => {
    my.postMessage(data);
  },
  onMessage: () => {
    const result = new Promise(function(resolve, reject) {
      try {
        my.onMessage = function(e) {
          resolve(e);
        };
      } catch (err) {
        console.log("ERROR", err);
      } finally {
      }
    });

    return result;
  }
};
