
import { Component, Vue, Prop } from "vue-property-decorator";

import { fromEvent, of, Subscription } from "rxjs";
import { delay, tap, map, filter, debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

@Component({
  name: "Search"
})
export default class Search extends Vue {
  @Prop({
    required: false
  })
  private keyword?: string;
  @Prop({
    required: false,
    default: "Enter your keyword to search"
  })
  private placeholder?: string;
  @Prop({
    required: false,
    default: []
  })
  private items?: Array<string>;
  @Prop({
    required: false,
    default: "id"
  })
  private itemKey!: string;

  private subscription?: Subscription;

  get _keyword() {
    return this.keyword;
  }

  set _keyword(newVal) {
    this.$emit("searchLoading", true);
    this.$emit("update:keyword", newVal);
  }

  activated() {
    // const toLower = (query) => {
    //   return query.toString().toLowerCase();
    // };
    // const isItemMatching = (item, query) =>
    //   toLower(item[this.itemKey] || item).startsWith(query.toString().toLowerCase());
    // const getItems = (query) => of(this.items?.filter((item) => isItemMatching(item, query))).pipe(delay(3000));
    // const onItemsLoadSuccess = (matchingItems) => {
    //   this.$emit("searchResult", matchingItems);
    //   this.$emit("searchLoading", false);
    // };
    // const searchElement = this.$refs.search as HTMLInputElement;
    // const search$ = fromEvent(searchElement, "input").pipe(
    //   map((event) => (event.target as HTMLInputElement)?.value),
    //   debounceTime(1000),
    //   distinctUntilChanged(),
    //   tap((query) => console.log("About to make an API call with query: " + query)),
    //   switchMap(getItems)
    // );
    // this.subscription = search$.subscribe(onItemsLoadSuccess);
  }

  // deactivated() {
  // this.subscription?.unsubscribe();
  // }
}

// import Vue from "vue";

// import { map, tap, debounceTime, distinctUntilChanged } from "rxjs/operators";

// export default Vue.extend({
//   domStreams: ["search$"],
//   props: {
//     keyword: {
//       default: ""
//     },
//     placeholder: {
//       default: "Search"
//     }
//   },
//   computed: {
//     _keyword: {
//       get: function() {
//         return this.keyword;
//       },
//       set: function(newValue) {
//         this.$emit("update:keyword", newValue);
//       }
//     }
//   },
//   subscriptions() {
//     return {
//       result: this.search$.pipe(
//         map(({ event }) => event.target.value),
//         distinctUntilChanged(),
//         debounceTime(100),
//         tap((v) => this.$emit("search", v))
//       )
//     };
//   }
// });
