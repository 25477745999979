import store from "@/store";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";

@Module({ store, dynamic: true, name: "uiStore" })
class UIStore extends VuexModule {
  isLoading: Boolean = true;

  @Mutation
  private SET_LOADER(isLoading: Boolean) {
    this.isLoading = isLoading;
  }

  @Action
  public async setLoader(isLoading: Boolean) {
    this.SET_LOADER(isLoading);
  }
}
export const uiStore = getModule(UIStore);
