<template>
  <div v-if="reviewOrder" class="relative mx-4 w-full max-w-5xl flex flex-col bg-white rounded-lg">
    <!-- header -->
    <div class="flex justify-end">
      <button @click="cancelReview" class="pr-4 pt-4">
        <SvgIcon name="close" class="w-5 h-5" />
      </button>
    </div>

    <!-- main -->
    <div class="flex flex-col pb-4">
      <div v-if="step == 0" key="0">
        <div class="flex flex-col items-center pb-4 pt-16">
          <div>
            <img class="h-16 object-cover mb-2" :src="reviewOrder.merchantLogoUrl" />
          </div>
          <div class="text-center">
            <span> How was your order at </span>
            <br />

            <span class="font-bold px-2">
              {{ reviewOrder.storeName }}
            </span>
          </div>
        </div>
        <div class="flex flex-col items-center pt-2">
          <vue-star-rate
            :rateRange="0"
            :maxIcon="5"
            :iconHeight="35"
            :iconWidth="35"
            :hasCounter="false"
            iconColor="#FFD127"
            iconColorHover="#FFD127"
            iconShape="star"
            v-on:ratingSet="onStarClick"
          ></vue-star-rate>
        </div>
        <div class="flex flex-col items-center pt-16 px-6 lg:px-64 text-gray-400">
          <span v-if="!STORE.comment.show" class="underline cursor-pointer" @click="STORE.comment.show = true"
            >Add comment</span
          >
          <textarea
            v-else
            type="text"
            v-model="STORE.comment.text"
            class="border w-full h-24 rounded p-2 border-gray-400 text-sm text-gray-700"
            placeholder="Leave a comment (Optional)"
          />
        </div>
      </div>
      <div v-else-if="step == 1" key="1">
        <div class="flex flex-col items-center pb-4 pt-16">
          <div class="relative rounded-full border border-gray-300">
            <img
              v-if="reviewOrder.deliveryCourier.photoUrl"
              class="h-16 w-16 rounded-full object-cover"
              :src="imageValid(reviewOrder.deliveryCourier.photoUrl)"
            />
            <SvgIcon v-else class="h-16 w-16 text-gray-400 p-4" name="person-outline" />
            <img class="absolute -bottom-1 -right-1 h-6 w-6 rounded-full object-cover" :src="deliveryVendorLogo" />
          </div>
          <div class="text-center mt-2">
            <span> How was your delivery experience? </span>
            <br />
            <br />
            <span class="text-gray-400"> Give thumbs up for good delivery. </span>
          </div>
        </div>
        <div class="flex flex-col items-center pt-8">
          <div class="flex justify-between w-32">
            <div
              class="circle cursor-pointer flex items-center justify-center"
              :class="[DELIVERY.stars == 0 ? 'circle-highlight' : '']"
              @click="
                () => {
                  DELIVERY.stars = 0;
                  DELIVERY.rated = true;
                }
              "
            >
              <SvgIcon name="thumbs-down-filled" class="w-6 h-6" />
            </div>
            <div
              class="circle cursor-pointer flex items-center justify-center"
              :class="[DELIVERY.stars == 1 ? 'circle-highlight' : '']"
              @click="
                () => {
                  DELIVERY.stars = 1;
                  DELIVERY.rated = true;
                }
              "
            >
              <SvgIcon name="thumbs-up-filled" class="w-6 h-6" />
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center pt-8 px-6 lg:px-64">
          <span
            v-if="!DELIVERY.comment.show"
            class="underline cursor-pointer text-gray-400"
            @click="DELIVERY.comment.show = true"
            >Add comment</span
          >
          <textarea
            v-else
            type="text"
            v-model="DELIVERY.comment.text"
            class="border w-full h-24 rounded p-2 border-gray-400 text-sm text-gray-700 focus:border-primary-400"
            placeholder="Leave a comment (Optional)"
          />
        </div>
      </div>
      <div v-else>
        <span class="p-8 pb-0 flex items-center justify-center text-center leading-relaxed">
          Your review has been submitted. Thank you for your time!
        </span>
      </div>
    </div>

    <div class="p-4 flex items-center justify-center mb-4">
      <button
        class="cta max-w-lg"
        @click="
          () => {
            if (isFinalStep) {
              handleClose();
            }
            handleContinue();
          }
        "
        :disabled="!enableContinue"
      >
        <span>{{ isFinalStep ? $t("Review.complete") : $t("Review.continue") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import vueStarRate from "vue-js-star-rating";
import { trxStore } from "@/store/modules/transaction";
import icons from "../../../src/assets/merchant_icons.svg";

export default {
  components: {
    vueStarRate
  },
  name: "Review",

  data() {
    return {
      step: 0,
      STORE: { stars: 0, rated: false, comment: { text: "", show: false } },
      DELIVERY: { stars: -1, rated: false, comment: { text: "", show: false } },
      imageUrl: ""
    };
  },
  computed: {
    enableContinue() {
      return this[this.steps[this.step]].rated;
    },
    isFinalStep() {
      return this.step + 1 == this.steps.length;
    },
    reviewOrder() {
      // return {
      //   merchantLogoUrl: "https://upload.wikimedia.org/wikipedia/en/1/12/Grab_%28application%29_logo.svg",
      //   storeName: "test",
      //   deliveryCourier: {
      //     photoUrl: "https://upload.wikimedia.org/wikipedia/en/1/12/Grab_%28application%29_logo.svg",
      //     // photoUrl: "https://sg-upload-appweb.lalamove.com/showhead.php?image_type=5&image_hash=uJx1LBSKXuIfM-_ve2TTHiuce2BckZpNIVOvyHgwbYJYyluLnnE5D9hSbT4vSujA&driver_id=1432741",
      //   },
      //   deliveryMethod:"LALAMOVE",
      //   orderDineType: "DELIVERY",
      // };
      return trxStore.reviewOrder;
    },
    steps() {
      switch (this.reviewOrder.orderDineType) {
        case "DELIVERY":
          return ["STORE", "DELIVERY"];
        default:
          return ["STORE"];
      }
    },
    deliveryVendorLogo() {
      switch (this.reviewOrder.deliveryMethod) {
        case "GRAB":
          return "https://smart-cafe-sandbox.oss-ap-southeast-3.aliyuncs.com/dev/delivery/grab.png";
        case "MRSPEEDY":
          return "https://smart-cafe-sandbox.oss-ap-southeast-3.aliyuncs.com/dev/delivery/borzo.png";
        case "LALAMOVE":
          return "https://smart-cafe-sandbox.oss-ap-southeast-3.aliyuncs.com/dev/delivery/lalamove.png";
        case "PANDAGO":
          return "https://smart-cafe-sandbox.oss-ap-southeast-3.aliyuncs.com/dev/delivery/pandago.jfif";
        default:
          return this.reviewOrder.merchantLogoUrl;
      }
    }
  },
  methods: {
    handleClose() {
      trxStore.setIsReviewOrder(false);
      this.$emit("closeReview", false);
    },
    cancelReview() {
      trxStore.postReview({
        orderID: this.reviewOrder.id,
        body: {
          type: this.steps[this.step],
          isCancel: true
        }
      });
      this.handleClose();
    },
    handleContinue() {
      trxStore.postReview({
        orderID: this.reviewOrder.id,
        body: {
          type: this.steps[this.step],
          rating: {
            stars: this[this.steps[this.step]].stars,
            comment: this[this.steps[this.step]].comment.text
          }
        }
      });
      if (!this.isFinalStep) {
        this.step += 1;
      }
    },
    onStarClick(rating) {
      this.STORE.stars = rating;
      if (rating == 0) {
        this.STORE.rated = false;
      } else {
        this.STORE.rated = true;
      }
    },
    imageValid(URL) {
      var tester = new Image();
      tester.onload = this.imageFound;
      tester.onerror = this.imageNotFound;
      tester.src = URL;
      return this.imageUrl;
    },
    imageFound() {
      // alert('That image is found and loaded');
      this.imageUrl = this.reviewOrder.deliveryCourier.photoUrl;
    },
    imageNotFound() {
      // alert('That image was not found.');
      this.imageUrl = icons;
    }
  }
};
</script>

<style lang="scss">
.circle {
  width: 60px;
  height: 60px;
  background-color: lightgrey;
  border-radius: 50%;
}

.circle:hover {
  background-color: var(--primary-400);
  color: var(--focused);
}

.circle-highlight {
  background-color: var(--primary-400);
  color: var(--focused);
}
</style>
