<script>
import { template, templateSettings } from "lodash";

// curly brace syntax
templateSettings.interpolate = /{{([\s\S]+?)}}/g;

// this would most likely be in a language file
const TEMPLATES_MAP = {
  alpha: " accepts only alphabet characters.",
  minLength: " must have at least {{min}} letters.",
  required: " is required.",
  between: ` must between {{min}} and {{max}}.`,
  email: ` is not in correct format.`
};

export default {
  props: {
    validation: {
      required: true,
      type: Object
    },
    fieldName: {
      required: false,
      default: "",
      type: String
    }
  },
  computed: {
    errors() {
      if (!this.invalid) {
        return [""];
      }
      return Object.keys(this.validation.$params).reduce((errors, validator) => {
        if (!this.validation[validator]) {
          const compiled = template(this.fieldName + TEMPLATES_MAP[validator]);
          errors.push(compiled(this.validation.$params[validator]));
        }
        return errors;
      }, []);
    },
    invalid() {
      return this.validation.$dirty && this.validation.$invalid;
    }
  },
  render() {
    return this.$scopedSlots.default({
      errors: this.errors,
      invalid: this.invalid
    });
  }
};
</script>
