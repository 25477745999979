<template>
  <div class="relative mx-4 w-full max-w-sm flex flex-col bg-white rounded-lg">
    <SvgIcon
      class="text-red-300 self-center m-8 w-10 h-10"
      name="information-circle"
    />

    <div class="flex flex-col pb-4 items-center">
      <span class="font-bold text-2xl pb-4">Alert</span>
      <span>You are now performing a future order.</span>
    </div>
    <div class="p-4 flex items-center justify-center mb-4">
      <button
        type="button"
        class="w-40 inline-flex justify-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium hover:bg-primary-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-600 focus-visible:ring-offset-2"
        @click="handleClose"
      >
        <span>{{ $t("FutureBookingModal.ok") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FutureBookingModal",
  methods: {
    handleClose() {
      this.$emit("closeBookingModal", false);
    }
  }
};
</script>

<style lang="scss"></style>
