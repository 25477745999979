<template>
  <transition name="fade">
    <div
      v-show="isLoading"
      class="fixed top-0 bottom-0 left-0 right-0 m-auto shadow bg-white bg-opacity-75 text-center transition-all duration-500"
      style="z-index: 1000;"
    >
      <img
        v-if="isBoost"
        class="absolute top-0 bottom-0 left-0 right-0 m-auto h-48 w-48 md:h-72 md:w-72"
        :src="loaderWebP"
        :onerror="`this.onerror=null; this.src='${loader}'`"
      />
      <svg
        v-else
        v-bind:style="styles"
        class="spinner spinner--circle absolute top-0 bottom-0 left-0 right-0 m-auto h-48 w-48 md:h-72 md:w-72"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </div>
  </transition>
</template>

<script>
import { isBoost } from "@/constant/platform";
import { getLoader } from "@/components/General/DynamicImages.vue";

export default {
  props: {
    isLoading: { default: false },
    size: {
      default: "80px"
    }
  },
  computed: {
    isBoost() {
      return isBoost();
    },
    loader() {
      return getLoader().loader;
    },

    loaderWebP() {
      return getLoader().loaderWebP;
    },

    styles() {
      let size = parseInt(this.size);
      return {
        width: this.size,
        height: this.size,
        transform: "scale(" + size / 75 + ")"
      };
    }
  }
};
</script>
<style lang="scss" scoped>
$offset: 187;
$duration: 1.4s;
.spinner {
  animation: circle-rotator $duration linear infinite;
  * {
    line-height: 0;
    box-sizing: border-box;
  }
}
@keyframes circle-rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: var(--primary-400);
  animation: circle-dash $duration ease-in-out infinite;
}
@keyframes circle-dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: calc($offset / 4);
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
</style>
