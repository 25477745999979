export const miniProgramPlatforms = ["ALIPAY", "TNGD", "BOOST", "SENHENG"];

export const isBoost = () => {
  // return window.navigator.userAgent.includes("boost");
  return false;
};

export const isSenheng = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const platform = urlParams.get("platform");
  return platform == "senheng";
};

export const isProduction = () => {
  const domainParts = window.location.host.split(".");
  const isProductionMerchantSubdomain =
    !window.location.host.startsWith("staging.alacarte.my") &&
    !window.location.host.startsWith("sb.alacarte.my") &&
    domainParts.length >= 2 &&
    !domainParts[0].startsWith("test") &&
    domainParts[1] == "alacarte";
  return (
    window.location.host.startsWith("alacarte.my") ||
    window.location.host.startsWith("web.alacarte.my") ||
    isProductionMerchantSubdomain
  );
};

export const isNestle = () => {
  if (
    window.location.host.startsWith("test-nestle.") ||
    window.location.host.startsWith("nestleprofessional.")
  ) {
    return true;
  }
  // else if (window.location.host.startsWith("localhost")) {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const merchant = urlParams.get("merchant");
  //   return merchant == "nestle";
  // }

  return false;
};

export const isInIframe = () => {
  return window.location !== window.parent.location;
};
