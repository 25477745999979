
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Notification extends Vue {
  mounted() {
    const { stack, notification }: any = this.$refs;
    stack.appendAfter(notification);
    console.log(stack, notification);
  }
}
