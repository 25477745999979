import store from "@/store";
import ITableReservation from "@/store/models/tableReservation";
import request from "@/utils/request";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import dayjs from "dayjs";
import cookies from "@/utils/cookies";

export interface ITableReservationState {
  tableReservation: ITableReservation | null;
}

@Module({ store, dynamic: true, name: "tableReservationStore" })
class TableReservationStore extends VuexModule implements ITableReservationState {
  public tableReservation: ITableReservation | null = {
    id: "",
    tableID: "",
    storeID: "",
    startAt: "",
    endAt: "",
    lockedUntil: null,
    noOfPax: 0,
    status: "",
    remark: "",
    selectedReserveDayIndex: 0,
    selectedReserveDeliveryTime: "",
  };

  @Mutation
  private UPDATE_RESERVE_DAY_INDEX(index: number) {
    if (this.tableReservation)
      this.tableReservation.selectedReserveDayIndex = index;
  }

  @Mutation
  private UPDATE_RESERVE_DELIVERY_TIME(time: string) {
    if (this.tableReservation)
      this.tableReservation.selectedReserveDeliveryTime = time;
  }

  @Mutation
  private UPDATE_TABLE_RESERVATION(payload: ITableReservation) {
    this.tableReservation = payload;
  }

  @Mutation
  private REMOVE_TABLE_RESERVATION() {
    this.tableReservation = null;
  }

  @Action
  public async updateReserveDayIndex(index: number) {
    this.UPDATE_RESERVE_DAY_INDEX(index);
  }

  @Action
  public async updateReserveDeliveryTime(time: string) {
    this.UPDATE_RESERVE_DELIVERY_TIME(time);
  }

  @Action
  public async reserveTable(payload): Promise<ITableReservation | null> {
    try {
      const { storeID, noOfPax, startAt, remark } = payload as {
        storeID: string;
        noOfPax: number;
        startAt: string;
        remark: string;
      };
      const { item } = (await request.post(`v3/web/store/${storeID}/reserve`, {
        noOfPax,
        startAt,
        remark
      })) as { item: ITableReservation | null };

      if (item) {
        this.setTableReservation({ ...item, selectedReserveDayIndex: this.tableReservation?.selectedReserveDayIndex, selectedReserveDeliveryTime: this.tableReservation?.selectedReserveDeliveryTime });
        cookies.set(`reservation-${storeID}`, JSON.stringify(item));
      }

      return item;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  @Action
  public setTableReservation(payload: ITableReservation) {
    // Check if the table reservation locked until period is still valid
    if (
      payload.lockedUntil &&
      dayjs(payload.lockedUntil)
        .utc()
        .isAfter(dayjs().utc())
    ) {
      this.UPDATE_TABLE_RESERVATION(payload);
    } else {
      this.removeTableReservation(payload.storeID);
    }
  }

  @Action
  public removeTableReservation(storeID: string) {
    this.REMOVE_TABLE_RESERVATION();
    cookies.remove(`reservation-${storeID}`);
  }
}
export const tableReservationStore = getModule(TableReservationStore);
