
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Toggle extends Vue {
  @Prop({
    required: true
  })
  checked?: Boolean;

  isChecked = this.checked;
  toggle() {
    this.isChecked = !this.isChecked;
    this.$emit("change", this.isChecked);
  }
}
