import Vue from "vue";
import Vuex from "vuex";

import { IUserState } from "./modules/user";
import { IMerchantState } from "./modules/merchant";
import { ICartState } from "./modules/cart";
import { IMenuState } from "./modules/menu";
import { IFoodState } from "./modules/food";
import { ITrxState } from "./modules/transaction";
import { IKeywordState } from "./modules/keyword";
import { IBannerState } from "./modules/banner";
import { ITableReservationState } from "./modules/tableReservation";

export interface IRootState {
  user: IUserState;
  merchant: IMerchantState;
  cart: ICartState;
  menu: IMenuState;
  food: IFoodState;
  trx: ITrxState;
  keyword: IKeywordState;
  banner: IBannerState;
  tableReservation: ITableReservationState;
}

Vue.use(Vuex);

export default new Vuex.Store<IRootState>({});
