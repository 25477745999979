
import { Vue, Component, Prop } from "vue-property-decorator";
import { isBoost, isSenheng, isProduction } from "@/constant/platform";

const requireAll = (requireContext: __WebpackModuleApi.RequireContext) => requireContext.keys().map(requireContext);

@Component
export default class SvgIcon extends Vue {
  @Prop({ type: String, required: true })
  name!: string;

  get isSenHeng() {
    // return isSenheng() || (cookies.get("isSenHeng") || "") == "YES";
    return isSenheng();
  }
  mounted() {
    let req;
    if (isBoost()) {
      req = require.context("@/assets/boost/svg", false, /\.svg$/);
    } else if (this.isSenHeng) {
      req = require.context("@/assets/senheng/svg", false, /\.svg$/);
    } else {
      req = require.context("@/assets/default/svg", false, /\.svg$/);
    }

    requireAll(req);
  }
}
