<template>
  <div>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$ionic.modalController.dismiss()" class="w-8 h-8">
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.82808 6.99999L7.77808 11.95L6.36408 13.364L7.60158e-05 6.99999L6.36408 0.635986L7.77808 2.04999L2.82808 6.99999Z"
                fill="black"
              />
            </svg>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons v-if="Object.keys(cart).length" slot="end">
          <ion-button @click="isManage = !isManage">{{
            isManage ? "Done" : "Manage"
          }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <main class="px-4 py-4">
        <div v-if="Object.keys(cart).length">
          <div
            v-for="(each, i) in Object.values(cart)"
            :key="i"
            @click="
              () => {
                $ionic.modalController.dismiss();
                $router.push({ name: 'Checkout', params: { id: each.id } });
              }
            "
            class="flex items-center justify-between space-x-3 hover:bg-gray-100 p-2 rounded-md transition cursor-pointer"
          >
            <div class="flex-none w-12 h-12 bg-gray-100 rounded">
              <img
                :src="
                  `${each.bannerUrl}?x-oss-process=image/resize,w_480,limit_1/format,webp`
                "
                class="w-full h-full object-contain"
                @error="
                  (e) =>
                    (e.target.src = `${BASE_URL}img/${platform}/empty_food.png`)
                "
              />
            </div>
            <div class="text-gray-900 flex-1 w-full self-start">
              <h1>{{ each.name }}</h1>
              <p class="text-gray-500 truncate text-sm">
                {{ each.cart.length }} items
                <span v-if="each.distance > -1"
                  >&#8729; {{ formatDistance(each.distance) }}</span
                >
              </p>
            </div>
            <button
              v-if="isManage"
              class="flex-none hover:text-red-500"
              @click.prevent="onRemoveCart($event, each)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="w-5 h-5 fill-current"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          v-else
          class="h-full w-full flex flex-col items-center text-sm p-2 pt-28 bg-white rounded-md"
        >
          <SvgIcon name="empty_cart" class="mx-auto" />
          <div class="my-6 text-center text-gray-800">
            <p class="text-gray-700 text-base font-bold">Cart is empty</p>
            <p class="text-gray-500">
              Add items to your cart and place order here.
            </p>
          </div>
          <button
            @click="
              () => {
                $ionic.modalController.dismiss();
              }
            "
            v-waves
            class="btn primary px-4 py-2 rounded-md mx-auto block font-bold text-center w-48 theme"
          >
            Continue Browsing
          </button>
        </div>
      </main>
    </ion-content>
  </div>
</template>

<script>
import request from "@/utils/request";
import { merchantStore } from "@/store/modules/merchant";
import cookies from "@/utils/cookies";

export default {
  name: "Modal",
  props: {
    title: { type: String, default: "My Cart" },
    $router: { default: {} },
    self: { default: {} },
    cart: {}
  },
  data() {
    return { isManage: false, deliveryLocation: {} };
  },
  computed: {
    platform() {
      return merchantStore.platform?.toLowerCase();
    },
    BASE_URL() {
      return process.env.BASE_URL;
    }
  },

  async mounted() {
    try {
      this.deliveryLocation =
        JSON.parse(await cookies.get("delivery:location")) || {};
    } catch (e) {
      console.error(e);
    }

    const payload = { storeId: Object.keys(this.cart) };
    this.deliveryLocation.lat &&
      this.deliveryLocation.lng &&
      Object.assign(payload, {
        latitude: this.deliveryLocation.lat,
        longitude: this.deliveryLocation.lng
      });

    request.post(`/v3/web/store-cart-info`, payload).then(({ items = [] }) => {
      items.forEach((item) => {
        if (this.cart[item?.id]) {
          this.cart[item.id] = { ...this.cart[item.id], ...item };
        }
      });
    });
  },
  methods: {
    formatDistance(distance) {
      if (+distance > 1000) {
        return `${(+distance / 1000).toFixed(1)} km`;
      } else {
        return `${+distance} m`;
      }
    },
    onRemoveCart(e, each) {
      e.stopPropagation();
      this.$ionic.alertController
        .create({
          header: "Confirmation",
          message: `Are you sure to remove ${each.name}'s cart?`,
          buttons: [
            {
              text: "Cancel",
              role: "cancel"
            },
            {
              text: "Okay",
              handler: () => {
                cookies.remove(`cart-${each.id}`);
                const cart = { ...this.cart };
                delete cart[each.id];
                this.cart = cart;
              }
            }
          ]
        })
        .then((a) => a.present());
    }
  }
};
</script>
<style>
ion-toast,
ion-modal {
  position: fixed !important;
}
</style>
