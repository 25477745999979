import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import ICart, { IAvailability } from "@/store/models/cart";
import IDeliveryFare from "@/store/models/store";
import IOrder from "@/store/models/order";
import { merchantStore } from "@/store/modules/merchant";
import request from "@/utils/request";
import md5 from "md5";
import cookies from "@/utils/cookies";
import { miniProgramPlatforms } from "@/constant/platform";
import { IPromo } from "../models/promo";
import { isAlaCartePage } from "@/utils/page";

export interface ICartState {
  cart: ICart[];
  totalItems: number;
  subtotalAmount: number;
}

@Module({ store, dynamic: true, name: "cartStore" })
class CartStore extends VuexModule implements ICartState {
  public cart: ICart[] = [];
  public totalItems = 0;
  public subtotalAmount = 0;

  get cartItems() {
    return this.totalItems;
  }
  get cartPrice() {
    return this.subtotalAmount;
  }
  get containsAlcohol() {
    return this.cart.some((item) => item.foodConformity == "ALCOHOL");
  }
  @Mutation
  private CLEAR_CART() {
    this.cart = [];
    this.cart.length = 0;
    this.totalItems = 0;
    this.subtotalAmount = 0;
  }

  @Mutation
  private ADD_TO_CART(payload) {
    if (!this.cart) {
      this.cart = [];
    }
    this.cart.push(payload);
  }

  @Mutation
  private CHANGE_QUANTITY(payload: { id: string; quantity: number }) {
    const index = this.cart.findIndex(
      (each: ICart) => each.cartItemID === payload.id
    );
    if (index > -1) {
      this.cart[index].itemQuantity = payload.quantity;
    }
  }

  @Mutation
  private UPDATE_CART(payload: ICart[]) {
    this.cart = payload || [];
  }

  @Mutation
  public async UpdateCartItemDineType(payload: string) {
    //hardcoded for mealplan
    this.cart[0].selectedDineTime = payload;
    cookies.set(
      `cart-${await cookies.get("currentStore")}`,
      JSON.stringify(this.cart)
    );
  }

  @Mutation
  private UPDATE_TOTAL_ITEMS() {
    this.totalItems = this.cart.reduce(function(total, item) {
      return total + item.itemQuantity;
    }, 0);
  }

  @Mutation
  private async UPDATE_SUBTOTAL() {
    this.subtotalAmount = 0;
    this.subtotalAmount = this.cart.reduce(function(total, item) {
      return (
        total +
        (item.combinationPricePerItem +
          item.comboTaxAmount -
          (item.campaignDiscountAmount || 0)) *
          item.itemQuantity
      );
    }, 0);
  }

  @Action
  public clearCart() {
    this.CLEAR_CART();
  }

  @Action
  public refreshSubtotal() {
    this.UPDATE_SUBTOTAL();
  }

  @Action
  public loadCart(payload: ICart[]) {
    this.UPDATE_CART(payload);
    this.UPDATE_TOTAL_ITEMS();
    this.UPDATE_SUBTOTAL();
  }

  @Action
  public async removeItemfromCartById(cartItemID: string) {
    const index = this.cart.findIndex(
      (each: ICart) => each.cartItemID === cartItemID
    );

    if (index > -1) {
      this.cart.splice(index, 1);
    }

    cookies.set(
      `cart-${await cookies.get("currentStore")}`,
      JSON.stringify(this.cart)
    );
    this.UPDATE_CART(this.cart);
    this.UPDATE_TOTAL_ITEMS();
    this.UPDATE_SUBTOTAL();
  }

  @Action
  public async addToCart(payload) {
    const cartId = payload.cartId;

    const hashedItem = md5(JSON.stringify(payload.food));
    const hashedSides = md5(JSON.stringify(payload.selectedSides));
    const hashedAttrs = md5(JSON.stringify(payload.selectedAttributes));
    const hashedRemark = md5(JSON.stringify(payload.remark));

    console.log("Payload: ", payload);

    const cartItemID = hashedItem + hashedAttrs + hashedSides + hashedRemark;

    const matchedCartItem = this.cart.find((each: ICart) => {
      return each.cartItemID === cartItemID;
    });

    if (
      matchedCartItem &&
      cartId === "" &&
      (payload.itemQuantity > 0 ||
        (payload.itemQuantity < 0 && matchedCartItem.itemQuantity > 0))
    ) {
      this.CHANGE_QUANTITY({
        id: cartItemID,
        quantity: matchedCartItem.itemQuantity + payload.itemQuantity
      });

      if (matchedCartItem.itemQuantity === 0) {
        this.removeItemfromCartById(matchedCartItem.cartItemID);
      }
    } else if (payload.itemQuantity > 0) {
      const isUsePlatformDetail = miniProgramPlatforms.includes(
        merchantStore.platform
      );

      const locale = merchantStore.locale;
      const foodName = isUsePlatformDetail
        ? payload.food?.platformDetail?.language?.[locale]?.name
        : payload.food?.language?.[locale]?.name || payload.food?.name;
      const cart = {
        item: {
          id: payload.food.id,
          name: foodName,
          amount: payload.food.amount,
          imageUrl: payload.food.imageUrl
        },
        selectedSides: payload.selectedSides,
        selectedAttributes: payload.selectedAttributes,
        cartItemID: cartItemID,
        combinationPricePerItem: payload.combinationPricePerItem,
        comboTaxAmount: payload.comboTaxAmount,
        campaignDiscountAmount: payload.campaignDiscountAmount,
        itemQuantity: payload.itemQuantity,
        remark: payload.remark,
        campaignID: payload.campaignID,
        selectedDineTime: payload.selectedDineTime,
        selectedDineType: payload.selectedDineType,
        mealPlan: payload.mealPlan,
        foodConformity: payload.food.foodConformity
      } as ICart;
      const index = this.cart.findIndex(
        (each: ICart) => each.cartItemID === cartId
      );
      if (payload.itemQuantity > 0) {
        if (index > -1) {
          this.cart.splice(index, 1, cart);
        } else {
          this.ADD_TO_CART(cart);
        }
      } else {
        if (index > -1) this.cart.splice(index, 1);
      }
    }
    cookies.set(
      `cart-${await cookies.get("currentStore")}`,
      JSON.stringify(this.cart)
    );
    this.UPDATE_CART(this.cart);
    this.UPDATE_TOTAL_ITEMS();
    this.UPDATE_SUBTOTAL();
  }

  @Action
  public async checkFoodAvailability(payload): Promise<IAvailability | null> {
    try {
      const {
        cart,
        deliveryTime,
        reorder = false,
        storeID = "",
        dineType,
        dineTime
      } = payload as {
        deliveryTime: string;
        cart: ICart[];
        reorder: boolean;
        storeID: string;
        dineType: string;
        dineTime: string;
      };
      const foods = cart.map((each) => {
        return {
          customerRemark: each.remark,
          id: each.item.id,
          orderQuantity: each.itemQuantity,
          orderAttributes: each.selectedAttributes.map((attribute) => {
            return {
              id: attribute.id,
              orderLabelIds: attribute.labels.map((label) => {
                return label.id;
              })
            };
          }),

          orderSides: each.selectedSides.map((side) => {
            return {
              id: side.id,
              delivery: {
                deliverAt: side.delivery?.deliverAt,
                dineTime: dineTime
              },
              orderItem: side.items.map((item) => {
                return {
                  id: item.id,
                  orderQuantity: 1,
                  price: item.price?.amount,
                  orderSides: item.sides?.map((side) => {
                    return {
                      id: side.id,

                      orderItem: side.items.map((item) => {
                        return {
                          id: item.id,
                          price: item.price?.amount,
                          orderQuantity: 1
                        };
                      })
                    };
                  }),

                  orderAttributes: item.attributes?.map((attribute) => {
                    return {
                      id: attribute.id,
                      orderLabelIds: attribute.labels.map((label) => {
                        return label.id;
                      })
                    };
                  })
                };
              })
            };
          })
        };
      });

      //TODO: make this an array of the same object to support multiple cart checkout
      const requestData = {
        orderFoods: foods,
        deliveryTime: deliveryTime,
        dineType: dineType
      } as any;

      const response: IAvailability = await request.post(
        `v3/web/store/${
          reorder ? storeID : merchantStore.currentStore?.id
        }/availability`,
        requestData
      );

      // merchantStore.UPDATE_CURRENT_STORE(response.store as IStore);

      return response;
    } catch (e) {
      return null;
    }
  }

  @Action
  public async createOrder(payload): Promise<IOrder | null> {
    try {
      const {
        dineType,
        cart,
        deliveryTime,
        deliveryType,
        vehicleNo,
        vehicleDescription,
        driveThruOption,
        pickupOption,
        deliveryAddressId,
        address,
        tableNo,
        tableReservationID,
        promoCode,
        isCutleryRequired,
        deliveryMethods,
        deliveryFares,
        specialFeeVendor,
        preOrderVendor,
        quotedDeliveryFee,
        dineTime
      } = payload as {
        dineType: string;
        deliveryTime: string;
        deliveryType: string;
        cart: ICart[];
        vehicleNo: string;
        vehicleDescription: string;
        driveThruOption: string;
        pickupOption: string;
        deliveryAddressId: number;
        address: any;
        tableNo: string;
        tableReservationID: string;
        promoCode: string;
        isCutleryRequired: boolean;
        deliveryMethods: Array<string>;
        deliveryFares: IDeliveryFare[];
        specialFeeVendor: string;
        preOrderVendor: string;
        quotedDeliveryFee: number;
        dineTime: string;
      };

      const foods = cart.map((each) => {
        return {
          customerRemark: each.remark,
          id: each.item.id,
          orderQuantity: each.itemQuantity,
          orderDineType: each.selectedDineType,
          orderAttributes: each.selectedAttributes.map((attribute) => {
            return {
              id: attribute.id,
              orderLabelIds: attribute.labels.map((label) => {
                return label.id;
              })
            };
          }),
          orderSides: each.selectedSides.map((side) => {
            return {
              id: side.id,
              delivery: {
                deliverAt: side.delivery?.deliverAt,
                dineTime: dineTime,
                address: address
              },
              orderItem: side.items.map((item) => {
                return {
                  id: item.id,
                  orderQuantity: 1,
                  orderSides: item.sides?.map((side) => {
                    return {
                      id: side.id,
                      orderItem: side.items.map((item) => {
                        return {
                          id: item.id,
                          orderQuantity: 1
                        };
                      })
                    };
                  }),

                  orderAttributes: item.attributes?.map((attribute) => {
                    return {
                      id: attribute.id,
                      orderLabelIds: attribute.labels.map((label) => {
                        return label.id;
                      })
                    };
                  })
                };
              })
            };
          })
        };
      });

      const requestData = {
        orderDineType: dineType,
        orderFoods: foods,
        deliveryTime: deliveryTime,
        deliveryType: deliveryType,
        vehicleNo: vehicleNo,
        vehicleDescription: vehicleDescription,
        pickupOption: pickupOption,
        driveThruOption: driveThruOption,
        deliveryAddressId,
        address,
        tableNo,
        tableReservationID,
        promoCode,
        isCutleryRequired: isCutleryRequired,
        deliveryMethods,
        deliveryFares,
        specialFeeVendor,
        preOrderVendor,
        quotedDeliveryFee,
        dineTime,
        isLanding: isAlaCartePage()
      } as any;

      const response: any = await request.post(
        `v3/web/store/${merchantStore.currentStore?.id}/order`,
        requestData
      );
      const { item } = response as {
        item: IOrder;
      };
      return item;
    } catch (e) {
      return null;
    }
  }

  @Action
  public async calculateDeliveryFees(payload) {
    try {
      const { item = { amount: 0 } } = (await request.post(
        `v3/web/store/${merchantStore.currentStore?.id}/delivery/calculate`,
        payload
      )) as {
        item: {
          amount: number;
          discountAmount: number;
          minSpentAmount: number;
        };
      };
      return item;
    } catch (e) {
      if ((e as any)?.data?.error?.code != "DELIVERY_NOT_AVAILABLE") {
        throw e;
      } else {
        return null;
      }
    }
  }
  @Action
  public async applyPromoCode(payload): Promise<IPromo | null> {
    try {
      payload.orderFoods = payload.cart?.map((each) => {
        return {
          id: each.item.id,
          orderQuantity: each.itemQuantity
        };
      });

      delete payload.cart;

      const response: any = await request.post(
        `v3/web/store/${merchantStore.currentStore?.id}/promo/apply`,
        payload
      );
      const { item } = response as { item: IPromo };
      return item;
    } catch (e) {
      return null;
    }
  }
}
export const cartStore = getModule(CartStore);
