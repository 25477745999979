import { isNestle } from "@/constant/platform";
import rmPlugin from "./plugin";

export default {
  set(key, value) {
    if (isNestle()) {
      rmPlugin.setStorage(key, value, "LOCAL_STORAGE");
    } else {
      localStorage.setItem(key, value);
    }
  },
  async get(key) {
    if (isNestle()) {
      return await rmPlugin.getStorage(key, "LOCAL_STORAGE");
    } else {
      return localStorage.getItem(key);
    }
  },
  remove(key) {
    if (isNestle()) {
      rmPlugin.removeStorage(key, "LOCAL_STORAGE");
    } else {
      localStorage.removeItem(key);
    }
  },
  setSessionStorage(key, value) {
    if (isNestle()) {
      rmPlugin.setStorage(key, value, "SESSION_STORAGE");
    } else {
      sessionStorage.setItem(key, value);
    }
  },
  async getSessionStorage(key) {
    let result: string | null = null;
    if (isNestle()) {
      return await rmPlugin.getStorage(key, "SESSION_STORAGE");
    } else {
      return sessionStorage.getItem(key);
    }
  },
  removeSessionStorage(key) {
    if (isNestle()) {
      rmPlugin.removeStorage(key, "SESSION_STORAGE");
    } else {
      sessionStorage.removeItem(key);
    }
  }
};
