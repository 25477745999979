<script>
import { merchantStore } from "@/store/modules/merchant";
import voucherIcon from "@/assets/boost/voucher.png";
import defaultFooterLogo from "@/assets/default/alacarte.png";
import boostFooterLogo from "@/assets/boost/alacarte.png";
import defaultEmptyFood from "@/assets/default/empty_food.png";
import boostEmptyFood from "@/assets/boost/empty_food.png";
import defaultLoader from "@/assets/default/loader.gif";
import defaultLoaderWebP from "@/assets/default/loader.webp";
import boostLoader from "@/assets/boost/loader.gif";
import boostLoaderWebP from "@/assets/boost/loader.webp";
import defaultSimpleLoader from "@/assets/default/simple_loader.gif";
import defaultSimpleLoaderWebP from "@/assets/default/simple_loader.webp";
import boostSimpleLoader from "@/assets/boost/simple_loader.gif";
import boostSimpleLoaderWebP from "@/assets/boost/simple_loader.webp";
import defaultLogo from "@/assets/default/logo.png";
import defaultLogoWebP from "@/assets/default/logo.webp";
import boostLogo from "@/assets/boost/logo.png";
import boostLogoWebP from "@/assets/boost/logo.webp";
import defaultEmptyShop from "@/assets/default/empty_shop.png";
import boostEmptyShop from "@/assets/boost/empty_shop.png";

import { isBoost, isProduction } from "@/constant/platform";

// Import as empty icons
export const DynamicImages = {};

// export const VoucherIcon = {
//   render(createElement) {
//     let component = (<SvgIcon name="promo" class="inline-block align-middle w-4 h-4 mr-1" />);
//     console.log("platform", merchantStore?.platform)
//     switch(merchantStore?.platform) {
//       case "BOOST":
//         component = createElement('img', {
//           attrs: {
//             src: voucherIcon,
//             class: 'w-4 h-4 mr-1'
//           }
//         })
//         break;
//     }
//     return component;
//   }
// };

export const FooterLogo = {
  render(createElement) {
    let component;
    if (isBoost()) {
      component = createElement("img", {
        attrs: {
          src: boostFooterLogo,
          class: "w-48"
        }
      });
    } else {
      component = createElement("img", {
        attrs: {
          src: defaultFooterLogo,
          class: "w-48"
        }
      });
    }
    return component;
  }
};

export const DefaultBanner = {
  render(createElement) {
    let component;
    if (isBoost()) {
      component = createElement("img", {
        attrs: {
          src:
            "https://asset.alacarte.my/assets/img/default-boost-banner.png?x-oss-process=image/resize,w_630,limit_1/format,webp",
          class: "w-full z-0 object-cover lg:rounded-lg shadow-xl banner",
          type: "image/webp",
          onerror:
            "this.onerror=null;this.src='https://asset.alacarte.my/assets/img/default-boost-banner.png';"
        }
      });
    } else {
      component = createElement("img", {
        attrs: {
          src:
            "https://asset.alacarte.my/assets/img/default-banner.png?x-oss-process=image/resize,w_630,limit_1/format,webp",
          class: "w-full z-0 object-cover lg:rounded-lg shadow-xl banner",
          type: "image/webp",
          onerror:
            "this.onerror=null;this.src='https://asset.alacarte.my/assets/img/default-banner.png';"
        }
      });
    }
    return component;
  }
};

export const getEmptyFoodPath = () => {
  let emptyFoodPath;
  if (isBoost()) {
    emptyFoodPath = boostEmptyFood;
  } else {
    emptyFoodPath = defaultEmptyFood;
  }
  return emptyFoodPath;
};

export const getEmptyShopPath = () => {
  let emptyShopPath;
  if (isBoost()) {
    emptyShopPath = boostEmptyShop;
  } else {
    emptyShopPath = defaultEmptyShop;
  }
  return emptyShopPath;
};

export const getLoader = () => {
  let loader;
  let loaderWebP;
  // switch(merchantStore?.platform) {
  //   case "WEB":
  //     loader = defaultLoader;
  //     loaderWebP = defaultLoaderWebP;
  //   case "BOOST":
  //     loader = boostLoader;
  //     loaderWebP = boostLoaderWebP;
  //     break;
  // }
  if (isBoost()) {
    loader = boostLoader;
    loaderWebP = boostLoaderWebP;
  } else {
    loader = defaultLoader;
    loaderWebP = defaultLoaderWebP;
  }
  return {
    loader,
    loaderWebP
  };
};

export const getSimpleLoader = () => {
  let simpleLoader;
  let simpleLoaderWebP;
  if (isBoost()) {
    simpleLoader = boostSimpleLoader;
    simpleLoaderWebP = boostSimpleLoaderWebP;
  } else {
    simpleLoader = defaultSimpleLoader;
    simpleLoaderWebP = defaultSimpleLoaderWebP;
  }
  return {
    simpleLoader,
    simpleLoaderWebP
  };
};

export const getLogo = () => {
  let logo;
  let logoWebP;
  if (isBoost()) {
    logo = boostLogo;
    logoWebP = boostLogoWebP;
  } else {
    logo = defaultLogo;
    logoWebP = defaultLogoWebP;
  }
  return {
    logo,
    logoWebP
  };
};

export default DynamicImages;
</script>
