
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Drawer extends Vue {
  @Prop({
    required: false,
    default: "left"
  })
  direction?: string;
  @Prop({
    required: true
  })
  isDrawerOpen?: boolean;
  @Prop({
    required: false
  })
  contentClass?: string;

  private translateDirectionClass = "-translate-x-full";

  get _isDrawerOpen() {
    return this.isDrawerOpen;
  }
  set _isDrawerOpen(open) {
    this.$emit("update:isDrawerOpen", open);
  }

  get drx() {
    return this.direction + "-0";
  }

  get transitionClass() {
    switch (this.direction) {
      case "right":
        return {
          enterClass: "translate-x-full",
          enterToClass: "translate-x-0",
          leaveClass: "translate-x-0",
          leaveToClass: "translate-x-full"
        };
      case "top":
        return {
          enterClass: "-translate-y-full",
          enterToClass: "translate-y-0",
          leaveClass: "translate-y-0",
          leaveToClass: "-translate-y-full"
        };
      case "bottom":
        return {
          enterClass: "translate-y-full",
          enterToClass: "translate-y-0",
          leaveClass: "translate-y-0",
          leaveToClass: "translate-y-full"
        };
      default:
        return {
          enterClass: "-translate-x-full",
          enterToClass: "translate-x-0",
          leaveClass: "translate-x-0",
          leaveToClass: "-translate-x-full"
        };
    }
  }
}
