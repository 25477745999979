import { isAlaCartePage, isSandbox } from "@/utils/page";
import { isBoost } from "@/constant/platform";
import Nprogress from "nprogress";
import Vue from "vue";
import VueAnalytics from "vue-analytics";
import VueRouter from "vue-router";

Nprogress.configure({ showSpinner: false });

Vue.use(VueRouter);

// const isSenHeng = () => {
//   return (cookies.get("isSenHeng") || "") == "YES";
// };

const routes = [
  {
    path: "/address",
    name: "AddressSelect",
    meta: { hideCart: true, ignoreStoreId: true, showBottomNav: false },
    component: () =>
      import(
        /* webpackChunkName: "address" */ "@/views/Address/AddressSelect.vue"
      )
  },

  {
    path: "/address/map",
    name: "Map",
    meta: { hideCart: true, ignoreStoreId: true, showBottomNav: false },
    component: () =>
      import(/* webpackChunkName: "address" */ "@/views/Address/Map.vue")
  },
  {
    path: "/",
    name: "StoreList",
    component: () =>
      !isAlaCartePage()
        ? import(/* webpackChunkName: "store-list" */ "@/views/StoreList.vue")
        : import(/* webpackChunkName: "store-list" */ "@/views/Home.vue"),
    meta: { hideCart: true, ignoreStoreId: true, showBottomNav: true }
  },
  {
    path: "/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "foodview" */ "@/views/Search.vue"),
    meta: { hideCart: true, ignoreStoreId: true, showBottomNav: true }
  },
  {
    path: "/:id/item/:foodId",
    name: "FoodView",
    component: () =>
      import(/* webpackChunkName: "foodview" */ "@/views/FoodView.vue"),
    meta: { transitionName: "slide", depth: 4, footerPaddingBottom: "pb-40" }
  },
  {
    path: "/:id/checkout",
    name: "Checkout",
    component: () =>
      import(/* webpackChunkName: "checkout"*/ "@/views/Checkout.vue"),
    meta: { transitionName: "slide", depth: 5 }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
    meta: {
      transitionName: "slide",
      depth: 7,
      requiresAuth: true,
      ignoreStoreId: true,
      showBottomNav: true
    }
  },
  {
    path: "/profile/address",
    name: "Address",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/AddAddress.vue"),
    meta: {
      transitionName: "slide",
      depth: 8,
      requiresAuth: true,
      footerPaddingBottom: "pb-40",
      ignoreStoreId: true
    }
  },
  {
    path: "/profile/vehicle",
    name: "Vehicle",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/VehicleInfo.vue"),
    meta: {
      transitionName: "slide",
      depth: 8,
      requiresAuth: true,
      ignoreStoreId: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Login.vue"),
    meta: { transitionName: "slide", depth: 7, ignoreStoreId: true }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () =>
      import(/* webpackChunkName: "profile" */ "@/views/Privacy.vue"),
    meta: { transitionName: "slide", depth: 7, ignoreStoreId: true }
  },

  {
    path: "/:id/home",
    name: "StoreView",
    component: () =>
      import(/* webpackChunkName: "store" */ "@/views/StoreView.vue"),
    meta: { transitionName: "slide", depth: 3, showBottomDrawer: true }
  },
  {
    path: "/order-summary",
    name: "OrderSummary",
    component: () =>
      import(/* webpackChunkName: "transaction" */ "@/views/OrderSummary.vue"),
    meta: {
      transitionName: "slide",
      depth: 6,
      requiresAuth: true,
      ignoreStoreId: true,
      showBottomNav: false
    }
  },
  {
    path: "/:id/order-history",
    name: "OrderHistory",
    redirect: { name: "Transaction" },
    //component: () => import(/* webpackChunkName: "transaction" */ "@/views/OrderHistory.vue"),
    meta: { transitionName: "slide", requiresAuth: true }
  },
  {
    path: "/transaction",
    name: "Transaction",
    component: () =>
      import(/* webpackChunkName: "transaction" */ "@/views/OrderHistory.vue"),
    meta: {
      transitionName: "slide",
      depth: 5,
      showBottomNav: true,
      requiresAuth: true,
      ignoreStoreId: true
    }
  },
  {
    path: "/voucher/:voucherId",
    name: "Voucher",
    component: () => import("@/views/VoucherTnC.vue"),
    meta: { transitionName: "slide", depth: 7, ignoreStoreId: true }
  },
  {
    path: "/campaign",
    name: "Campaign",
    component: () => import("@/views/Campaign.vue"),
    meta: { transitionName: "slide", depth: 7, ignoreStoreId: true }
  },
  {
    path: "/:id/",
    redirect: "/:id/home"
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    }

    let axis = { x: 0, y: 0 };
    if (savedPosition) {
      axis = savedPosition;
    }
    return axis;
  },
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any): Promise<any> {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete

        resolve(this.currentRoute);
      },
      (error) => {
        // on abort

        // only ignore NavigationDuplicated error
        if (
          error.name === "NavigationDuplicated" ||
          error.message.startsWith("Navigation cancelled")
        ) {
          console.log("supressed nav error", error.message);
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

router.beforeResolve((to, from, next) => {
  if (to.path) {
    Nprogress.start();
  }
  next();
});

router.afterEach(() => {
  Nprogress.done();
});

let googleAnalyticsID = isSandbox() ? "UA-183093743-2" : "UA-183093743-1";

if (isBoost()) {
  googleAnalyticsID = isSandbox() ? "UA-210083336-1" : "UA-210083336-2";
}
Vue.use(VueAnalytics, {
  id: googleAnalyticsID,
  router
});

export default router;
