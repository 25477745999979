import { locales } from "./locale";

import en from "./messages/en.json";
import cn from "./messages/cn.json";

export const messages = {
  [locales.EN]: en,
  [locales.CN]: cn
};

export const defaultLocale = locales.EN;