
import { Prop, Component, Vue } from "vue-property-decorator";
import Drawer from "@/components/Feedback/Drawer.vue";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import clipboard from "@/utils/clipboard";
import detectMobile from "@/utils/user-agent";

import { userStore } from "@/store/modules/user";
import { merchantStore } from "@/store/modules/merchant";
import { trxStore } from "@/store/modules/transaction";
import { isNestle } from "@/constant/platform";
@Component({
  components: {
    Drawer
  }
})
export default class SideMenu extends Vue {
  @Prop({
    required: true
  })
  isDrawerOpen?: boolean;
  isPhoneVisible = false;

  formatNumber(number: string): string {
    return parsePhoneNumberFromString(number, "MY")?.number.toString() || "";
  }

  logout() {
    userStore.logout();
  }

  get _isDrawerOpen() {
    return this.isDrawerOpen;
  }
  set _isDrawerOpen(open) {
    this.$emit("update:isDrawerOpen", open);
  }

  get isStoreView() {
    const { id = "" } = this.$route.params;
    return id !== "";
  }

  get userStore() {
    return userStore;
  }

  get currentStore() {
    return merchantStore.currentStore;
  }

  get shareableLink() {
    return `${window.location.protocol}//${window.location.host}/${this.currentStore?.id}`;
  }

  get isMobile() {
    return detectMobile();
  }

  isNestle() {
    return isNestle();
  }

  navigateToTranscation(dineType: string) {
    this._isDrawerOpen = false;
    trxStore.SET_IS_MEAL_PLAN(dineType == "MEAL_PLAN");
    this.$router.push({ name: "Transaction" });
  }

  copyLink() {
    const result = clipboard(this.shareableLink);
    if (result !== false) {
      this.$ionic.toastController
        .create({
          header: "Link copied",
          position: "top",
          color: "primary",
          duration: 5000,
          buttons: [
            {
              icon: "close",
              role: "cancel"
            }
          ]
        })
        .then((x) => x.present());
    }
  }
}
