import { isBoost } from "@/constant/platform";
import store from "@/store";
import IOrder from "@/store/models/order";
import ITransaction from "@/store/models/transaction";
import cookies from "@/utils/cookies";
import { isAlaCartePage } from "@/utils/page";
import request from "@/utils/request";
import qs from "qs";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import dayjs, { Dayjs } from "dayjs";
import { IAddress } from "@/store/models/user";

export interface ITrxState {
  allOrders: {
    items: IOrder[];
    cursor: string;
  };
  activeOrders: {
    items: IOrder[];
    cursor: string;
  };
  unpaidOrders: {
    items: IOrder[];
    cursor: string;
  };
}

@Module({ store, dynamic: true, name: "trxStore" })
class TransactionStore extends VuexModule implements ITrxState {
  public allOrders = {
    items: [] as IOrder[],
    cursor: ""
  };
  public activeOrders = {
    items: [] as IOrder[],
    cursor: ""
  };
  public unpaidOrders = {
    items: [] as IOrder[],
    cursor: ""
  };
  public reviewOrder: IOrder | null = null;
  public isReviewOrder = false;
  public isMealPlan = false;
  public isOngoingBoostPayment: boolean = false;
  public mealPlanAddress: (IAddress | undefined)[] | undefined = [];
  public filteredDeliveryTime: Dayjs[] = [];

  @Mutation
  public SET_BOOST_PAYING_FLAG(payload: boolean) {
    this.isOngoingBoostPayment = payload;
  }

  @Mutation
  public setIsReviewOrder(payload: boolean) {
    this.isReviewOrder = payload;
  }

  @Mutation
  private UPDATE_ALL_ORDERS(payload) {
    this.allOrders = {
      items: [...this.allOrders.items, ...payload.items],
      cursor: payload.cursor
    };
  }
  @Mutation
  private UPDATE_ACTIVE_ORDERS(payload) {
    this.activeOrders = {
      items: [...this.activeOrders.items, ...payload.items],
      cursor: payload.cursor
    };
  }
  @Mutation
  private UPDATE_UNPAID_ORDERS(payload) {
    this.unpaidOrders = {
      items: [...this.unpaidOrders.items, ...payload.items],
      cursor: payload.cursor
    };
  }
  @Mutation
  private CLEAR_ORDER_HISTORY() {
    this.allOrders = {
      items: [],
      cursor: ""
    };
    this.activeOrders = {
      items: [],
      cursor: ""
    };
    this.unpaidOrders = {
      items: [],
      cursor: ""
    };
  }
  @Mutation
  private UPDATE_ORDER(order: IOrder) {
    const alllOrdersIndex = this.allOrders.items.findIndex(
      (each) => each.id === order.id
    );
    if (alllOrdersIndex > -1) {
      this.allOrders.items.splice(alllOrdersIndex, 1, order);
    }

    const activeOrdersIndex = this.activeOrders.items.findIndex(
      (each) => each.id === order.id
    );
    if (activeOrdersIndex > -1) {
      this.activeOrders.items.splice(activeOrdersIndex, 1, order);
    }

    const unpaidIndex = this.unpaidOrders.items.findIndex(
      (each) => each.id === order.id
    );
    if (unpaidIndex > -1) {
      if (order.paymentStatus != "PENDING") {
        this.unpaidOrders.items.splice(unpaidIndex, 1);
      }
    }
  }

  @Mutation
  private SET_FOOD_DELIVERY_TIME(deliveryTime: string[][]) {
    this.filteredDeliveryTime = deliveryTime
      .map((each, index) => {
        return dayjs()
          .add(index, "day")
          .startOf("day");
      })
      .filter((time, i) => deliveryTime[i].length <= 0);
  }

  @Mutation
  public SET_IS_MEAL_PLAN(isMealPlan: boolean) {
    this.isMealPlan = isMealPlan;
    cookies.set("isTransactionListMealPlan", this.isMealPlan);
  }

  @Mutation
  public SET_MEAL_PLAN_ADDR(payload: { address: IAddress; dateIndex: number }) {
    if (this.mealPlanAddress) {
      this.mealPlanAddress.splice(payload.dateIndex, 1, payload.address);
    }
  }
  @Mutation
  public SET_MEAL_PLAN_ADDRESSES(payload) {
    this.mealPlanAddress = payload;
  }
  @Mutation
  private SET_REVIEW_ORDER(order: IOrder) {
    this.reviewOrder = order;
  }
  @Action
  public clearOrderHistory() {
    this.CLEAR_ORDER_HISTORY();
  }
  @Action
  public async getALLOrders({
    merchantID,
    orderDineType
  }: {
    merchantID: string;
    orderDineType: string[];
  }): Promise<any> {
    try {
      const response: any = await request.get(
        `v3/web/orders?${qs.stringify(
          {
            merchantID,
            cursor: this.allOrders.cursor,
            orderDineType
          },
          { arrayFormat: "repeat" }
        )}`
      );
      const { items = [], cursor } = response as {
        items: IOrder[];
        cursor: string;
      };

      this.UPDATE_ALL_ORDERS({
        items,
        cursor
      });
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  async postReview(req) {
    try {
      request.post(`v3/web/review/order/${req.orderID}`, req.body);
    } catch (e) {
      console.error(e);
    }
  }

  @Action
  public async getActiveOrders({
    merchantID,
    orderDineType
  }: {
    merchantID: string;
    orderDineType: string[];
  }): Promise<any> {
    try {
      const response: any = await request.get(
        `v3/web/orders?${qs.stringify(
          {
            merchantID,
            deliveryStatus: [
              "WAIT_FOR_CONFIRMATION",
              "PROGRESSING",
              "WAITING_FOR_COURIER",
              "FINDING_COURIER",
              "SHIPPING",
              "DELAYED",
              "IN_RETURN"
            ],
            paymentStatus: "DONE",
            cursor: this.activeOrders.cursor,
            orderDineType
          },
          { arrayFormat: "repeat" }
        )}`
      );

      const { items = [], cursor } = response as {
        items: IOrder[];
        cursor: string;
      };

      this.UPDATE_ACTIVE_ORDERS({
        items,
        cursor
      });
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  public async getUnpaidOrders({
    merchantID,
    orderDineType
  }: {
    merchantID: string;
    orderDineType: string[];
  }): Promise<any> {
    try {
      const response: any = await request.get(
        `v3/web/orders?${qs.stringify(
          {
            merchantID,
            paymentStatus: "PENDING",
            cursor: this.unpaidOrders.cursor,
            orderDineType
          },
          { arrayFormat: "repeat" }
        )}`
      );
      const { items = [], cursor } = response as {
        items: IOrder[];
        cursor: string;
      };

      this.UPDATE_UNPAID_ORDERS({
        items,
        cursor
      });
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  public async getOrderById(id: string) {
    try {
      const response: any = await request.get(`v3/web/orders/${id}`);
      const { item, deliveryTime } = response as {
        item: IOrder;
        deliveryTime: string[][];
      };
      this.UPDATE_ORDER(item);
      if (deliveryTime) {
        this.SET_FOOD_DELIVERY_TIME(deliveryTime);
      }

      return item;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  @Action
  public async cancelOrderById(id: string) {
    try {
      const response: any = await request.post(`v3/web/order/${id}/canceled`);
      const { item, deliveryTime } = response as {
        item: IOrder;
        deliveryTime: string[][];
      };
      this.UPDATE_ORDER(item);
      if (deliveryTime) {
        this.SET_FOOD_DELIVERY_TIME(deliveryTime);
      }
      return item;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  @Action
  public async getReviewOrder(id) {
    let url = "v3/web/review/order";

    if (id) {
      url += `/${id}`;
    }

    try {
      const response: any = await request.get(url);
      const { item } = response as {
        item: IOrder;
      };
      this.SET_REVIEW_ORDER(item);

      return item;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
  @Action
  public async updateMealPlan(payload: any) {
    try {
      const {
        orderID = "",
        sideID = "",
        date = null,
        address = null
      } = payload as {
        orderID: string;
        sideID: string;
        date: Dayjs;
        address: IAddress;
      };
      const response: any = await request.patch(
        `v3/web/orders/${orderID}`,
        payload
      );
      const { item, deliveryTime } = response as {
        item: IOrder;
        deliveryTime: string[][];
      };
      this.UPDATE_ORDER(item);
      if (deliveryTime) {
        this.SET_FOOD_DELIVERY_TIME(deliveryTime);
      }

      return item;
    } catch (e) {
      console.error(e);
    }
  }
  @Action
  public async createTransaction(payload) {
    try {
      const {
        paymentType = "",
        paymentMethod = "",
        storeID = "",
        orderIDs = []
      } = payload as {
        paymentType: string;
        paymentMethod: string;
        storeID: string;
        orderIDs: string[];
      };

      const response: any = await request.post(
        `v3/web/store/${storeID}/transaction`,
        {
          paymentType,
          paymentMethod,
          orderIDs,
          isLanding: isAlaCartePage()
        }
      );
      const { item } = response as {
        item: {
          transaction: ITransaction;
          paymentRedirectUrl: string;
          transactionData: string;
        };
      };

      if (item) {
        return item;
      }
    } catch (e) {
      console.error(e);
    }
  }
}
export const trxStore = getModule(TransactionStore);
