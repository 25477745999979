<template>
  <div>
    <div v-if="$route.meta.showBottomNav" class="block lg:hidden pb-14"></div>
    <div
      v-if="$route.meta.showBottomNav"
      :class="showNav ? 'bottom-0' : '-bottom-24'"
      class="fixed left-0 z-50 block w-full h-14 transition-all duration-200 ease-in-out lg:hidden"
    >
      <div
        class="flex items-center justify-between px-6 space-x-4 bg-white border-t border-gray-200 rounded-t-lg bg-opacity-90 bg-blur navbar-safe-area"
      >
        <router-link
          v-waves
          :to="{ name: isNestle ? 'StoreView' : 'StoreList' }"
          class="block w-1/4 leading-none text-center py-2"
        >
          <SvgIcon
            v-if="isCurrentRoute('/')"
            name="home_active"
            class="w-6 h-6 mx-auto"
          />
          <SvgIcon v-else name="home_inactive" class="w-6 h-6 mx-auto" />
          <small
            class="text-xs"
            :class="isCurrentRoute('/') ? 'text-black font-bold' : 'text-black'"
            >{{ $t("App.home") }}</small
          >
        </router-link>
        <div
          v-waves
          @click="setTranscationPage"
          class="block w-1/4 leading-none text-center py-2"
        >
          <SvgIcon
            v-if="isCurrentRoute('/transaction') && !isMealPlan"
            name="transaction_active"
            class="w-6 h-6 mx-auto"
          />
          <SvgIcon v-else name="transaction_inactive" class="w-6 h-6 mx-auto" />
          <small
            class="text-xs"
            :class="
              isCurrentRoute('/transaction')
                ? 'text-black font-bold'
                : 'text-black'
            "
            >{{ $t("App.order-history") }}</small
          >
        </div>
        <router-link
          v-waves
          :to="{ name: 'Profile' }"
          class="block w-1/4 leading-none text-center py-2"
        >
          <SvgIcon
            v-if="isCurrentRoute('/profile')"
            name="profile_active"
            class="w-6 h-6 mx-auto"
          />
          <SvgIcon v-else name="profile_inactive" class="w-6 h-6 mx-auto" />
          <small
            class="text-xs"
            :class="
              isCurrentRoute('/profile') ? 'text-black font-bold' : 'text-black'
            "
            >{{ $t("App.profile") }}</small
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { trxStore } from "@/store/modules/transaction";
import { isNestle } from "@/constant/platform";
export default {
  data() {
    return {
      ticking: false,
      previousScrollTop: 0,
      showNav: true,
      isSetTimeout: false
    };
  },
  //this component is outside of keep-alive
  created() {
    document.addEventListener("scroll", (e) => {
      if (!this.ticking) {
        window.requestAnimationFrame(() => {
          this.showNav = this.previousScrollTop > window.scrollY;
          this.previousScrollTop = window.scrollY;
          if (!this.showNav && !this.isSetTimeout) {
            this.isSetTimeout = true;
            setTimeout(() => {
              this.showNav = window.scrollY == this.previousScrollTop;

              this.isSetTimeout = false;
            }, 1000);
          }
          this.ticking = false;
        });

        this.ticking = true;
      }
    });
  },
  computed: {
    isMealPlan() {
      return trxStore.isMealPlan;
    },
    isNestle() {
      return isNestle();
    }
  },
  methods: {
    isCurrentRoute(route) {
      return window.location.pathname == route;
    },
    setTranscationPage() {
      trxStore.SET_IS_MEAL_PLAN(false);
      this.$router.push({ name: "Transaction" });
    }
  }
};
</script>
