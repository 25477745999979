
import { Component, Vue, Prop } from "vue-property-decorator";
import { getEmptyFoodPath, getEmptyShopPath } from "@/components/General/DynamicImages.vue";

@Component
export default class Picture extends Vue {
  @Prop({
    required: true
  })
  readonly urls!: string[];
  @Prop({
    required: false
  })
  readonly name?: string;
  @Prop({
    required: false
  })
  readonly imgClass?: string;
  @Prop({
    required: false,
    default: getEmptyFoodPath()
  })
  readonly isFood?: boolean;
  @Prop({
    required: false,
    default: true
  })
  readonly errorImgSrc?: string;
  url = this.urls?.[0] || "";
  handleError() {
    if (this.isFood) {
      this.url = getEmptyFoodPath();
    } else {
      this.url = getEmptyShopPath();
    }
  }
}
