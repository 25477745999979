
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({
    required: true,
    default: false
  })
  isModalOpen!: boolean;

  get _isModalOpen() {
    return this.isModalOpen;
  }

  set _isModalOpen(open) {
    this.$emit("update:isModalOpen", open);
  }
  @Watch("isModalOpen")
  onStateChaged(newVal) {
    document.getElementsByTagName("body")[0].style.overflow = newVal
      ? "hidden"
      : "auto";
  }
}
