// @flow
// import "regenerator-runtime/runtime";

export default {
  sendMessage: null as any,
  sendNotification: null as any,
  receiveMessage: null as any,
  isInitilized: false as boolean,
  isRegistered: false as boolean,
  init(origin, callback) {
    if (this.isInitilized) {
      callback("");
      return;
    }
    if (!this.isRegistered) {
      this.isRegistered = true;
      this.receiveMessage = (event) => {
        if (event.origin.indexOf(origin) < 0) return;

        this.sendMessage = (msg) => {
          event.source.postMessage(JSON.stringify(msg), event.origin);
        };

        this.sendNotification = (type, message) => {
          this.sendMessage({
            action: "SHOW_NOTIFICATION",
            type,
            message
          });
        };

        this.isInitilized = true;
        callback(event.data);

        this.sendMessage({
          action: "FINISH_HANDSHAKE"
        });
        window.onhashchange = () => {
          this.sendMessage({
            action: "URL_CHANGE",
            message: window.location.href
          });
        };

        window.removeEventListener("message", this.receiveMessage);
      };
      window.addEventListener("message", this.receiveMessage, false);
    } else {
      if (this.isInitilized) {
        callback("");
        return;
      } else {
        setTimeout(() => {
          if (this.isInitilized) {
            callback("");
            return;
          } else {
            const errMsg = "Plugin init failed";
            const err = new Error(errMsg);
            alert(errMsg);
            console.error(err);
            throw err;
          }
        }, 1500);
      }
    }
  },

  toggleLoader(show) {
    this.sendMessage({
      action: "TOGGLE_LOADER",
      type: show ? "show" : "hide"
    });
  },

  setStorage(key, value, type) {
    this.sendMessage({
      action: "SET_" + type,
      type: key,
      message: value
    });
  },

  removeStorage(key, type) {
    this.sendMessage({
      action: "REMOVE_" + type,
      type: key
    });
  },

  getStorage(key, type): Promise<string | null> {
    //TODO: check on any concurrency issues in case of bug
    return new Promise((resolve, reject) => {
      const callback = function(event) {
        if (typeof event.data !== "string") return;
        if (event.data.startsWith("{") && !event.data.startsWith("setImmediate")) {
          const x = JSON.parse(event.data);
          switch (x.action) {
            case "GET_" + type:
              if (key === x.type) {
                resolve(x.data);
                window.removeEventListener("message", callback);
              }
              break;
          }
          return;
        }
      };

      window.addEventListener("message", callback, false);

      this.sendMessage({
        action: "GET_" + type,
        type: key
      });
    });
  },

  showToast(type, message) {
    this.sendNotification(type, message);
  },

  inIframe: () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
};
