import "@/assets/styles.scss";
import SvgIcon from "@/components/General/SvgIcon.vue";
import { defaultLocale, messages } from "@/i18n";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import "leaflet/dist/leaflet.css";
import { VClosePopover, VPopover, VTooltip } from "v-tooltip";
import Vue, { DirectiveOptions } from "vue";
import VueI18n from "vue-i18n";
import VueLazyload from "vue-lazyload";
import Meta from "vue-meta";
import App from "./App.vue";
import * as directives from "./directives";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import miniprogram from "./utils/mini-program";
import { isAlaCartePage } from "@/utils/page";
import { isBoost, isSenheng } from "@/constant/platform";
// import VueKeepAliveDev from "vue-keep-alive-dev";

import VueKeepAliveDev from "vue-keep-alive-dev";

if (isBoost()) {
  document.getElementsByTagName("html")[0].classList.add("boost");
}

// if (isSenheng() || (cookies.get("isSenHeng") || "") == "YES") {
if (isSenheng()) {
  document.getElementsByTagName("html")[0].classList.add("senheng");
}

Vue.use(VueKeepAliveDev, {
  environment: "development" // Your environment when HMR is in use
});

// Vue.use(VueKeepAliveDev, {
//   environment: "development" // Your environment when HMR is in use
// });

// const isSandbox = page.isSandbox();
// Sentry.init({
//   Vue: Vue,
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "alacarte.my", /^\//]
//     })
//   ],

//   environment: isSandbox ? "sandbox" : "production",
//   dsn: "https://e4d8a8ae1d3f4149b4431e6137ab21b9@sentry-alacarte.ap.ngrok.io/2",
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0
// });

Vue.config.productionTip = false;

import Ionic from "@ionic/vue";
import "@ionic/core/css/core.css";
import PortalVue from "portal-vue";

Vue.use(PortalVue);
Vue.use(Ionic, { mode: "ios" });
Vue.config.ignoredElements = [/^ion-/];

Vue.component("SvgIcon", SvgIcon);

Vue.use(VueLazyload, {
  lazyComponent: true,
  attempt: 1,
  preLoad: 1.3
});

Vue.use(Meta);
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);
Vue.use(VueI18n);
const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

Object.keys(directives).forEach((key) => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key]);
});

declare module "vue/types/vue" {
  interface Vue {
    $miniProgram: any;
  }
}
Object.defineProperty(Vue.prototype, "$miniProgram", { value: miniprogram });

new Vue({
  router,
  store: store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

export { i18n };
