import store from "@/store";
import IMenu from "@/store/models/menu";
import ICampaign from "@/store/models/campaign";
import { merchantStore } from "@/store/modules/merchant";
import request from "@/utils/request";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import applyPercentageDiscount from "@/utils/discount";
import IFood from "../models/food";

export interface IMenuState {
  campaigns: Map<string, ICampaign>;
  menus: IMenu[];
  allFoods: IFood[] | null;
}

@Module({ store, dynamic: true, name: "menuStore" })
class MenuStore extends VuexModule implements IMenuState {
  public campaigns: Map<string, ICampaign> = new Map();
  public menus: IMenu[] = [];
  public allFoods: IFood[] | null = [];
  public isLoading = false;

  @Mutation
  private UPDATE_MENUS(payload: IMenu[]) {
    this.menus = payload
      .filter(
        (each) => each.status === "ACTIVE" && (each.foods?.length as number) > 0
      )
      // .sort((a, b) => {
      //   if (a.position > b.position) return 1;
      //   if (b.position > a.position) return -1;

      //   return 0;
      // })
      .map((each) => {
        if (!each.foods) return each;
        // each.foods.sort((a, b) => {
        //   if (!a) return 0;
        //   if (!b) return 0;
        //   if (a.position > b.position) return 1;
        //   if (b.position > a.position) return -1;

        //   return 0;
        // });
        return each;
      });

    this.allFoods = this.menus.reduce((allFoods: IFood[], menu) => {
      if (!menu.isItemGroup) {
        return [...allFoods, ...(menu.foods || [])];
      } else {
        return [];
      }
    }, []);
  }
  @Mutation
  private UPDATE_CAMPAIGNS(payload: Map<string, ICampaign>) {
    this.campaigns = payload;
  }
  @Mutation
  private CLEAR_MENU() {
    this.isLoading = true;
    this.menus = [];
  }
  @Mutation
  private SET_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Action
  public async fetchMenus(storeID: string) {
    try {
      const url = `v3/web/store/${storeID}/foods?approvedOnly=${merchantStore.isShowApprovedOnly}`;
      const { campaigns, items } = (await request.get(url)) as {
        items: IMenu[];
        campaigns: Map<string, ICampaign>;
      };
      this.UPDATE_MENUS(items ? items : []);
      this.UPDATE_CAMPAIGNS(campaigns ? campaigns : new Map());
      return items;
    } catch (e) {
      console.error(e);
    } finally {
      this.SET_LOADING(false);
    }
  }
  @Action
  public loadMenu(menus: IMenu[]) {
    this.UPDATE_MENUS(menus);
  }
  @Action
  public clearMenu() {
    this.CLEAR_MENU();
  }
  get getCampaignID() {
    return (campaignIDs: string[] | null, price: number) => {
      const validCampaigns = campaignIDs
        ?.filter((cid) => {
          const c = this.campaigns?.[cid] as ICampaign;
          return (
            c &&
            c.discount.minSpendAmount == 0 &&
            c.perOrderLimit == null &&
            c.userBalance == null
          );
        })
        .sort((x, y) => {
          //prioritize giving more discounts to customers
          const p1 = this.getCampaignPrice(x, price);
          const p2 = this.getCampaignPrice(y, price);
          if (p1 > p2) return 1;
          if (p2 > p1) return -1;
          return 0;
        });
      // console.log("campaign price", validCampaigns)

      return validCampaigns?.[0] || null;
    };
  }

  get getCampaignPrice() {
    return (cid: string | null, price: number) => {
      if (cid) {
        const c = this.campaigns?.[cid] as ICampaign;
        switch (c.discount.type) {
          case "FIXED":
            return Math.max(0, price - c.discount.amount);
          case "PERCENTAGE":
            return applyPercentageDiscount(price, c.discount.amount);
          default:
            break;
        }
      }
      return -1;
    };
  }

  get toTaxInclusive() {
    return (amount: number, rate: number) => {
      return amount + amount * (rate / 10000);
    };
  }

  get toFormattedTaxInclusive() {
    return (amount: number, rate: number) => {
      return ((amount + amount * (rate / 10000)) / 100)?.toFixed(2);
    };
  }
}
export const menuStore = getModule(MenuStore);
