
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { merchantStore } from "@/store/modules/merchant";
import { userStore } from "@/store/modules/user";
import { cartStore } from "@/store/modules/cart";
import { Button } from "@/components";
import { isAlaCartePage } from "@/utils/page";
import { getLogo } from "@/components/General/DynamicImages.vue";
import CartModal from "@/components/Custom/CartModal.vue";
import { isNestle } from "@/constant/platform";

@Component({
  components: {
    Button
  }
})
export default class Header extends Vue {
  @Prop({
    required: false,
    default: false
  })
  isDrawerOpen?: boolean;
  isCartHide?: boolean;

  headerName = "à la carte";
  headerTo: { name: string; params?: {} } = { name: "StoreList" };

  onViewCart() {
    this.$ionic.modalController
      .create({
        component: CartModal as any,
        componentProps: {
          data: {},
          propsData: {
            title: "My Cart",
            $router: this.$router,
            $t: this.$t,
            self: this
          }
        }
      })
      .then((m) => m.present());
  }

  get isNestle() {
    return isNestle();
  }

  get cartStoreTotal() {
    return cartStore.totalItems;
  }

  get logo() {
    return getLogo().logo;
  }
  get logoWebP() {
    return getLogo().logoWebP;
  }
  get _isDrawerOpen() {
    return this.isDrawerOpen;
  }

  get _isCartHide() {
    return this.$route.meta?.hideCart;
  }

  set _isDrawerOpen(open) {
    this.$emit("update:isDrawerOpen", open);
  }

  get subtotal() {
    return cartStore.subtotalAmount;
  }

  get totalItems() {
    return cartStore.totalItems;
  }

  get storeName() {
    return merchantStore.currentStore ? merchantStore.currentStore.name : "";
  }
  get merchant() {
    return merchantStore.merchant;
  }
  get merchantName() {
    return this.merchant
      ? this.merchant.brandName !== ""
        ? this.merchant.brandName
        : this.merchant.name
      : "";
  }
  get userName() {
    return userStore.user.profile?.name;
  }
  get user() {
    return userStore.user;
  }
  get userStore() {
    return userStore;
  }

  get isStoreView() {
    const { id = "" } = this.$route.params;
    return id !== "";
  }

  get isAlaCartePage() {
    return isAlaCartePage();
  }

  get isAlaCarteRootPage() {
    const isRootPath = window.location.pathname === "/";
    return isAlaCartePage() && isRootPath;
  }

  get isLandingPage() {
    const isRootPath = window.location.pathname === "/";
    return isRootPath;
  }

  get currentStore() {
    return merchantStore.currentStore;
  }

  @Watch("currentStore")
  @Watch("merchant")
  @Watch("$route", { deep: true })
  async routeChange() {
    let title = "";
    if (this.isStoreView || isNestle()) {
      this.headerTo = {
        name: "StoreView",
        params: { id: this.currentStore?.id }
      };
      this.headerName = this.storeName;
    } else if (!isAlaCartePage() && !this.isStoreView) {
      this.headerTo = { name: "StoreList" };
      this.headerName = this.merchantName;
      if (this.headerName !== "") {
        title = this.merchantName;
      }
    } else {
      this.headerTo = { name: "StoreList" };
      this.headerName = "à la carte";
      title = "à la carte";
    }
    if (title !== "") {
      document.title = title;
    }
  }

  @Watch("currentStore")
  async storeChange() {
    if (this.isStoreView && this.storeName !== "") {
      document.title = `${this.storeName} - ${this.merchantName}`;
    }
  }
}
